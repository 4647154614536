import {
  LeftOutlined,
  CloudUploadOutlined,
  PlusCircleOutlined,
  CloudDownloadOutlined,
  UserOutlined,
  IdcardOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";

import AdvancedButton from "~/components/AdvancedButton/AdvancedButton";
import AdvancedForm from "~/components/AdvancedForm/AdvancedForm";
import AdvancedInput from "~/components/AdvancedInput/AdvancedInput";
import PanelContentBreadcrumb from "~/components/PanelContentBreadcrumb/PanelContentBreadcrumb";

import { maskPhone, unmaskField } from "~/services/masks";
import { translate } from "~/services/i18n";
import * as MessageService from "~/services/message";

import * as UserActions from "~/actions/user";

import { getRouteStackPath } from "~/config/routes";

import { getPagetType } from "~/utils/page";

import { PAGE_TYPE } from "~/enum/pageType";

import { useReduxState } from "~/hooks/useReduxState";
import { ProfileType } from "~/enum/profileType";
import AdvancedCheckbox from "~/components/AdvancedCheckbox/AdvancedCheckbox";
import AdvancedUploader from "@portal/components/AdvancedUploader/AdvancedUploader";
import { FileSchedule } from "~/models/module";
import { FileType } from "@portal/enum/newsType";
import { Popconfirm } from "antd";

const formInitialValues: models.User = {
  id: "",
  name: "",
  email: "",
  cellphone: "",
  profileType: ProfileType.APP_USER,
  mustChangePassword: false,
  password: "",
  confirmPassword: "",
  isMinister: false,
  files: [],
  photo: "",
  digitalDocument: "",
};

const UserDetails: React.FC = () => {
  const [form, setForm] = useState(formInitialValues);
  const [isMinister, setIsMinister] = useState(false);
  const [pageType] = useState(getPagetType());
  const [file, setFile] = useState<FileSchedule | null>();
  const [isRemovedCard, setIsRemovedCard] = useState(false);

  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const { pathname } = useLocation();

  const { user } = useReduxState();

  const onFormChange = (key: string, val: any) => {
    setForm((prevState: models.User) => ({ ...prevState, [key]: val }));
  };

  useEffect(() => {
    if (user && user.detail) {
      setForm(user.detail);
      if (user.detail.profileType === ProfileType.MINISTER_USER) {
        setIsMinister(true);
      } else {
        setIsMinister(false);
      }

      if (user.detail.cellphone) {
        onFormChange("cellphone", user.detail.cellphone.substring(2));
      }
    } else {
      setForm(formInitialValues);
    }
  }, [user.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(UserActions.getDetail(params.id));
    } else {
      dispatch(UserActions.cleanDetails());
    }
  }, [pathname, pageType]);

  const onFormSubmit = () => {
    const fileList = [];

    if (!form.name) {
      return MessageService.error("APPLICATION.ERRORS.EMPTY_NAME");
    }

    if (!form.cellphone) {
      return MessageService.error("APPLICATION.ERRORS.EMPTY_CELLPHONE");
    }

    if (!form.email) {
      return MessageService.error("APPLICATION.ERRORS.EMPTY_EMAIL");
    }

    if (pageType === PAGE_TYPE.ADD || form.mustChangePassword) {
      if (!form.password) {
        return MessageService.error("APPLICATION.ERRORS.EMPTY_PASSWORD");
      }

      if (form.password !== form.confirmPassword) {
        return MessageService.error("APPLICATION.ERRORS.MISMATCH_PASSWORD");
      }
    }

    if (file) {
      fileList.push({
        id: form.files
          ? form.files.find(
              (item: { file: { thumbnail: boolean } }) => !item.file?.thumbnail
            )?.file?.id
          : null,
        name: file?.name || null,
        type: FileType.IMAGE,
        base64: file?.base64 || null,
        extension: file?.extension || null,
        isPrivate: false,
      });
    }

    const payload: any = {
      ...form,
      email: form.email.toLowerCase().trim(),
      name: form.name.trim(),
      cellphone: `55 ${unmaskField(form.cellphone)}`,
      profileType: isMinister
        ? ProfileType.MINISTER_USER
        : ProfileType.APP_USER,
      files: fileList,
    };

    if (pageType === PAGE_TYPE.EDIT && !form.mustChangePassword) {
      delete payload.password;
      delete payload.confirmPassword;
    }

    if (payload.profileType !== ProfileType.MINISTER_USER && !isMinister) {
      payload.files = [];
    }

    if (pageType === PAGE_TYPE.EDIT) {
      if (payload.files[0]?.base64 && !isRemovedCard) {
        const digitalDocument: models.DigitalDocument = {
          isPrivate: false,
          base64: payload.files[0]?.base64,
        };
        dispatch(UserActions.registerPhoto(digitalDocument, payload.id));
      }

      if (isRemovedCard) {
        const digitalDocument: models.DigitalDocument = {
          isPrivate: false,
          base64: null,
        };
        dispatch(UserActions.registerPhoto(digitalDocument, payload.id));
      } 
        
      dispatch(UserActions.update(user?.detail?.id, payload));
    } else {
      dispatch(UserActions.create(payload));
    }
  };

  return (
    <Container fluid className="details">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                title: translate("PAGES.PANEL.USER.USER.REPORT.TITLE"),
                url: getRouteStackPath("USER", "USER_REPORT"),
                active: true,
              },
              {
                title: translate(
                  `PAGES.PANEL.USER.USER.DETAILS.TITLE_${
                    getPagetType() === PAGE_TYPE.ADD ? "ADD" : "EDIT"
                  }`
                ),
                active: false,
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="details__title">
            <h3 className="details__title__text">
              {translate(
                `PAGES.PANEL.USER.USER.DETAILS.TITLE_${
                  getPagetType() === PAGE_TYPE.ADD ? "ADD" : "EDIT"
                }`
              )}
            </h3>
            <p className="details__title__description">
              {translate(
                `PAGES.PANEL.USER.USER.DETAILS.PAGE_DESCRIPTION_${
                  getPagetType() === PAGE_TYPE.ADD ? "ADD" : "EDIT"
                }`
              )}
            </p>
          </div>
          <AdvancedForm onSubmit={onFormSubmit}>
            <div className="details__form">
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate(
                      "PAGES.PANEL.USER.USER.DETAILS.FORM.FIELDS.NAME.LABEL"
                    )}
                    value={form.name}
                    onChange={(val: string) => onFormChange("name", val)}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    label={translate(
                      "PAGES.PANEL.USER.USER.DETAILS.FORM.FIELDS.EMAIL.LABEL"
                    )}
                    value={form.email}
                    onChange={(val: string) => onFormChange("email", val)}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    label={translate(
                      "PAGES.PANEL.USER.USER.DETAILS.FORM.FIELDS.CELLPHONE.LABEL"
                    )}
                    value={maskPhone(form.cellphone)}
                    onChange={(val: string) => onFormChange("cellphone", val)}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
              </Row>

              {pageType === PAGE_TYPE.EDIT && (
                <Row>
                  <Col>
                    <AdvancedCheckbox
                      label={translate(
                        "PAGES.PANEL.USER.USER.DETAILS.FORM.FIELDS.MUST_CHANGE_PASSWORD.LABEL"
                      )}
                      value={form.mustChangePassword}
                      onChange={(val: boolean) =>
                        onFormChange("mustChangePassword", val)
                      }
                    />
                  </Col>
                </Row>
              )}

              {(pageType === PAGE_TYPE.ADD || form.mustChangePassword) && (
                <Row>
                  <Col md={4}>
                    <AdvancedInput
                      type="password"
                      label={translate(
                        "PAGES.PANEL.USER.USER.DETAILS.FORM.FIELDS.PASSWORD.LABEL"
                      )}
                      value={form.password}
                      onChange={(val: string) => onFormChange("password", val)}
                    />
                  </Col>
                  <Col md={4}>
                    <AdvancedInput
                      type="password"
                      label={translate(
                        "PAGES.PANEL.USER.USER.DETAILS.FORM.FIELDS.CONFIRM_PASSWORD.LABEL"
                      )}
                      value={form.confirmPassword}
                      onChange={(val: string) =>
                        onFormChange("confirmPassword", val)
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <AdvancedButton
                      variant="text"
                      text={translate("SHARED.SEND_PASSWORD")}
                      disabled
                    />
                  </Col>
                </Row>
              )}
              {pageType === PAGE_TYPE.EDIT && (
                <Row className="pl-3">
                  <Popconfirm
                    title={translate("SHARED.CONFIRM_UNMINISTER_USER")}
                    onConfirm={() => setIsMinister(false)}
                    okText={translate("SHARED.YES")}
                    cancelText={translate("SHARED.NO")}
                    disabled={!isMinister}
                  >
                    <AdvancedCheckbox
                      label={translate("SHARED.TURN_USER_MINISTER")}
                      value={isMinister}
                      checked={isMinister}
                      onChange={() => setIsMinister(true)}
                    />
                  </Popconfirm>
                </Row>
              )}
              {form.profileType === ProfileType.MINISTER_USER && isMinister && (
                <>
                  <h4 className="details__title__subtext advanced-file-upload-minister__title">
                    {translate(
                      `PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.LABEL_PORTFOLIO`
                    )}
                  </h4>
                  <Row className="mb-4">
                    <Col md={6} xl={4}>
                      <div className="advanced-file-upload-minister__preview-detail">
                        {form?.photo ? (
                          <img
                            src={`${form.photo}?${Date.now()}`}
                            alt="preview"
                          />
                        ) : (
                          <div className="advanced-file-upload-minister__preview-default">
                            <UserOutlined className="advanced-file-upload-minister__preview-icon" />
                          </div>
                        )}
                        {form?.photo && (
                          <label>
                            {translate("SHARED.PREVIEW_IMAGE_MINISTER")}
                          </label>
                        )}
                        <AdvancedButton
                          className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                          text={translate(
                            "PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.BUTTON_DOWNLOAD_PORTFOLIO"
                          )}
                          disabled={!form?.photo}
                          onClick={() =>
                            dispatch(UserActions.downloadPhoto(form.id))
                          }
                          endIcon={<CloudDownloadOutlined />}
                        />
                      </div>
                    </Col>
                    <Col md={6} xl={4}>
                      <div className="advanced-file-upload-minister__container-card">
                        {(form.digitalDocument || file?.base64) &&
                        !isRemovedCard ? (
                          <img
                            src={
                              file?.base64
                                ? file?.base64
                                : `${form.digitalDocument}?${Date.now()}`
                            }
                            alt="preview"
                          />
                        ) : (
                          <div className="advanced-file-upload-minister__preview-card">
                            <UserOutlined className="advanced-file-upload-minister__preview-card-icon" />
                          </div>
                        )}
                        <AdvancedUploader
                          thumb
                          onChange={(x) => {
                            setIsRemovedCard(false);
                            setFile(x);
                          }}
                          hidePreview
                          value={
                            file?.base64 ||
                            (form &&
                              form.files &&
                              form.files.find(
                                (item: { file: { thumbnail: boolean } }) =>
                                  item.file?.thumbnail
                              )?.file?.url)
                          }
                        >
                          <div className="advanced-file-upload-minister__container-card">
                            <AdvancedButton
                              className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                              text={translate(
                                "PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.BUTTON_UPLOAD_PORTFOLIO"
                              )}
                              endIcon={<CloudUploadOutlined />}
                            />
                          </div>
                        </AdvancedUploader>
                        <AdvancedButton
                          className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                          text={translate(
                            "PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.BUTTON_REMOVE_PORTFOLIO"
                          )}
                          onClick={() => setIsRemovedCard(true)}
                          disabled={(!form.digitalDocument && !file?.base64) || isRemovedCard}
                          endIcon={<DeleteOutlined />}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              )}

              <div className="details__form__actions">
                <Row className="details__form__actions__row">
                  <Col md="auto">
                    <Link to={getRouteStackPath("USER", "USER_REPORT")}>
                      <AdvancedButton
                        variant="text"
                        text={translate("SHARED.BACK")}
                        startIcon={<LeftOutlined />}
                      />
                    </Link>
                  </Col>
                  <Col md="auto">
                    <AdvancedButton
                      type="submit"
                      text={translate("SHARED.SAVE")}
                      endIcon={<PlusCircleOutlined />}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </AdvancedForm>
        </Col>
      </Row>
    </Container>
  );
};

export default UserDetails;
