import {
  CloudUploadOutlined,
  LeftOutlined,
  PlusCircleOutlined,
  DownloadOutlined,
  DeleteOutlined,
  ControlOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as CampaignsActions from '@portal/actions/campaign';
import { getAllSynods } from '@portal/actions/synod';
import { getAddressByCep } from '@portal/actions/utils';
import AdvancedCheckbox from '@portal/components/AdvancedCheckbox/AdvancedCheckbox';
import AdvancedUploader from '@portal/components/AdvancedUploader/AdvancedUploader';
import { EventType, Scope } from '@portal/enum/typeEvent';
import { useReduxState } from '@portal/hooks/useReduxState';
import * as MessageService from '@portal/services/message';
import { removeSpecialChars } from '@portal/utils/format';
import { cepMask, phoneMask } from '@portal/utils/string';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import { FileSchedule } from '~/models/module';
import { translate } from '~/services/i18n';
import { getPagetType } from '@portal/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';
import { useLocation, useParams, Link } from 'react-router-dom';
import PanelContentBreadcrumb from '@portal/components/PanelContentBreadcrumb/PanelContentBreadcrumb';
import { getRouteStackPath } from '@portal/config/routes';
import { DateTime } from 'luxon';
import { maskDate } from '@portal/services/masks';
import { ProfileType } from '@portal/enum/profileType';
import { FileType } from '@portal/enum/newsType';
import AdvancedTextInput from '@portal/components/AdvancedTextInput/AdvancedTextInput';
import { remove } from '../../../../actions/file';
import AdvancedDrag from '@portal/components/AdvancedDrag/AdvancedDrag';
import { openPdf } from '@portal/utils/fileUtils';
import AdvancedSelect from '@portal/components/AdvancedSelect/AdvancedSelect';

const initialValues: models.AddSchedule = {
  name: '',
  startsAtDate: null,
  endsAtDate: null,
  type: EventType.CAMPAIGN,
  title: '',
  description: '',
  street: '',
  number: '',
  neighborhood: '',
  isMain: false,
  zipcode: '',
  city: '',
  state: '',
  complement: '',
  latitude: '',
  longitude: '',
  miscInfo: '',
  email: '',
  isActive: true,
  phone: '',
  synodId: null,
  mediaUrl: '',
  scope: Scope.SYNODAL,
  files: [],
};

const CampaignsDetails: React.FC = () => {
  const [form, setForm] = useState(initialValues);
  const dispatch = useDispatch();
  const [checkBox, setCheckBox] = useState(false);
  const [synodCheckBox, setSynodCheckBox] = useState(false);
  const [fileList, setFileList] = useState<models.FileItem[]>([]);
  const [fileThumbnail, setFileThumbnail] = useState<FileSchedule | null>();
  const [file, setFile] = useState<FileSchedule | null>();
  const { synod, user, campaigns } = useReduxState();
  const [removedList, setRemovedList] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [fileToExclude, setFileToExclude] = useState<any | null>();
  

  const [pageType] = useState(getPagetType());
  const params = useParams() as { id: string };
  const { pathname } = useLocation();

  const onFormChange = (
    key: string,
    value: string | null | number | undefined | boolean
  ) => {
    setForm((prevState: models.AddSchedule) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    dispatch(getAllSynods());
  }, []);

  useEffect(() => {
    if (campaigns && campaigns.detail) {
      setForm(campaigns.detail);
      onFormChange('zipcode', campaigns.detail.address?.zipcode);
      onFormChange('city', campaigns.detail.address?.city);
      onFormChange('state', campaigns.detail.address?.state);
      onFormChange('street', campaigns.detail.address?.street);
      onFormChange('neighborhood', campaigns.detail.address?.neighborhood);
      onFormChange('longitude', campaigns.detail.address?.latitude);
      onFormChange('latitude', campaigns.detail.address?.longitude);
      onFormChange('number', campaigns.detail.address?.number);
      onFormChange('complement', campaigns.detail.address?.complement);
      setFileList(campaigns.detail.files.filter((item: any) => item.file.type === FileType.PDF).map((item: any) => { return item.file}))
      if (campaigns.detail.startsAtDate) {
        onFormChange(
          'startsAtDate',
          DateTime.fromISO(campaigns.detail.startsAtDate as string).toLocaleString(
            DateTime.DATE_SHORT
          )
        );
      }

      if (campaigns.detail.endsAtDate) {
        onFormChange(
          'endsAtDate',
          DateTime.fromISO(campaigns.detail.endsAtDate as string).toLocaleString(
            DateTime.DATE_SHORT
          )
        );
      }
      onFormChange('phone', campaigns.detail.phone.substring(2));
      if (campaigns.detail.synodId) {
        setSynodCheckBox(true);
      }

      if (campaigns.detail.email || (campaigns.detail.phone && campaigns.detail.phone !== '55')) {
        setCheckBox(true);
      }
    } else {
      setForm(initialValues);
      setCheckBox(false);
      setSynodCheckBox(false);
      setFileList([])
    }
  }, [campaigns.detail]);

  useEffect(() => {
    setCheckBox(false);
    setSynodCheckBox(false);
    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(CampaignsActions.getDetail(params.id));
    } else {
      dispatch(CampaignsActions.cleanDetails());
    }
  }, [pathname, pageType]);

  const getAddress = (zipcode: string) => {
    dispatch(
      getAddressByCep(zipcode, (data) => {
        if (data) {
          onFormChange('neighborhood', data.data.bairro);
          onFormChange('city', data.data.localidade);
          onFormChange('street', data.data.logradouro);
          onFormChange('complement', data.data.complemento);
          onFormChange('zipcode', data.data.cep);
          onFormChange('state', data.data.uf);
        }
      })
    );
  };

  const setReportLinkYoutube = (linkYT: string) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;

    const match = linkYT.match(regExp);
    let videoId = null;
    if (match && match[7].length === 11) {
      videoId = match[7];
    }
    if (linkYT.length > 24) {
      if (videoId === null) {
        return MessageService.error(
          'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.ERRORS.MEDIA_URL'
        );
      }
    }
    onFormChange('mediaUrl', linkYT);
  };

  const handleSetFileList = (file: models.FileItem) => {
    if (file.uid) {
      setFileList([...fileList, file]);
    }
  };

  const onFormSubmit = () => {
    const imageList = [];

    if (
      (form.startsAtDate &&
        !DateTime.fromFormat(form.startsAtDate as string, 'dd/MM/yyyy')
          .toJSDate()
          .getTime()
      )
      || (
        !form.startsAtDate && form.endsAtDate
      )
    ) {
      return MessageService.error(
        translate('PAGES.PANEL.NEW_ACTIVITIES.CAMPAIGN.DETAILS.ERRORS.INIT_DATE')
      );
    }

    if (synodCheckBox && !form.synodId) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_SYNOD_ID');
    }

    if (
      form.endsAtDate &&
      !DateTime.fromFormat(form.endsAtDate as string, 'dd/MM/yyyy')
        .toJSDate()
        .getTime()
    ) {
      return MessageService.error(
        translate('PAGES.PANEL.NEW_ACTIVITIES.CAMPAIGN.DETAILS.ERRORS.END_DATE')
      );
    }

    if (!form.title) {
      return MessageService.error(
        translate('PAGES.PANEL.NEW_ACTIVITIES.CAMPAIGN.DETAILS.ERRORS.TITLE')
      );
    }

    if (!form.name) {
      return MessageService.error(
        translate('PAGES.PANEL.NEW_ACTIVITIES.CAMPAIGN.DETAILS.ERRORS.NAME')
      );
    }

    if (!form.type) {
      return MessageService.error(
        translate('PAGES.PANEL.NEW_ACTIVITIES.CAMPAIGN.DETAILS.ERRORS.TYPE')
      );
    }

    if (!form.description) {
      return MessageService.error(
        translate(
          'PAGES.PANEL.NEW_ACTIVITIES.CAMPAIGN.DETAILS.ERRORS.DESCRIPTION'
        )
      );
    }

    if (form?.startsAtDate && form.endsAtDate) {
      const initDate = DateTime.fromFormat(
        form.startsAtDate as string,
        translate('SHARED.FORMAT_DATE')
      ).toISODate()  || '';

      const finalDate = DateTime.fromFormat(
        form.endsAtDate as string,
        translate('SHARED.FORMAT_DATE')
      ).toISODate()  || '';

      if (new Date(initDate).getTime() > new Date(finalDate).getTime()) {
        return MessageService.error('APPLICATION.ERRORS.INVALID_DATE');
      }
    }

    if (file) {
      imageList.push(
        {
          id: form.files ? form.files.find((item: { file: { thumbnail: boolean; } }) =>
            !item.file?.thumbnail)?.file?.id : null,
          name: file?.name || null,
          type: FileType.IMAGE,
          base64: file?.base64 || null,
          extension: file?.extension || null,
          isPrivate: false,
        }
      );
    }

    if (fileThumbnail) {
      imageList.push(
        {
          id: form.files ? form.files.find((item: { file: { thumbnail: boolean; } }) =>
            item.file?.thumbnail)?.file?.id : null,
          name: fileThumbnail?.name || null,
          type: FileType.IMAGE,
          base64: fileThumbnail?.base64 || null,
          extension: fileThumbnail?.extension || null,
          thumbnail: true,
          isPrivate: false,
        }
      );
    }

    if( fileList && fileList.length > 0) {
      fileList.map((item, index) => {
        imageList.push({
          id: fileList[index].id || null,
          order: index,
          title: fileList[index].title || fileList[index].file.title,
          name: item.name,
          type: FileType.PDF,
          base64: item.base64,
          extension: item.extension,
          isPrivate: false,
        })
      })
    }

    let phoneNumber = `55${removeSpecialChars(form.phone)}`;
    let email = form.email;

    if (campaigns.detail?.phone && !checkBox) {
      phoneNumber = '';
    }

    if (campaigns.detail?.email && !checkBox) {
      email = '';
    }

    const data: models.AddSchedule = {
      ...form,
      email,
      phone: phoneNumber,
      startsAtDate: form.startsAtDate && DateTime.fromFormat(
        form.startsAtDate as string,
        translate('SHARED.FORMAT_DATE')
      ).toISODate(),
      endsAtDate: form.endsAtDate && DateTime.fromFormat(
        form.endsAtDate as string,
        translate('SHARED.FORMAT_DATE')
      ).toISODate(),
      address: {
        zipcode: form.zipcode,
        street: form.street,
        neighborhood: form.neighborhood,
        city: form.city,
        state: form.state,
        number: form.number,
        latitude: form.latitude,
        longitude: form.longitude,
      },
      files: imageList,
    };

    if (form.type !== EventType.CAMPAIGN) {
      delete data.files;
    }

    if (pageType === PAGE_TYPE.EDIT) {
      let scope = form.scope;
      let synodId = form.synodId;

      if (form.synodId && !user.me?.synod?.id) {
        if (synodCheckBox) {
          scope = Scope.SYNODAL;
        } else {
          synodId = null;
          scope = Scope.NATIONAL;
        }
      }
      
      dispatch(CampaignsActions.update(params.id, { ...data, scope, synodId }));
    } else {
      if (user.me?.user.profileType === ProfileType.SYNOD_ADMIN) {
        dispatch(CampaignsActions.create({ ...data, synodId: user.me?.synod?.id, scope: Scope.SYNODAL }));
      } else {
        let scope = form.scope;
        let synodId = form.synodId;

        if (form.synodId) {
          if (synodCheckBox) {
            scope = Scope.SYNODAL;
          } else {
            synodId = null;
            scope = Scope.NATIONAL;
          }
        } else {
          scope = Scope.NATIONAL;
        }

        dispatch(CampaignsActions.create({ ...data, scope, synodId }));
      }
    }
  };

  const handleOk = async () => {
    if (fileToExclude.id) {
      setRemovedList([...removedList, file]);
      await remove(fileToExclude.id)
    }
    const foundIndex = fileList.findIndex((o) => o.name === fileToExclude.name);
    if (foundIndex === -1) {
      return;
    }
    fileList.splice(foundIndex, 1);
    setFileList([...fileList]);
    setModalOpen(false)
  }

  const handleCancel = () => {
    setModalOpen(false)
  }

  return (
    <div className="schedule-details">
      <Container fluid className="details">
        <Row>
          <Col>
            <PanelContentBreadcrumb
              items={[
                {
                  title: translate(
                    'PAGES.PANEL.CAMPAIGNS.REPORT.PAGE_TITLE'
                  ),
                  url: getRouteStackPath('ACTIVITIES', 'CAMPAIGNS_REPORT'),
                  active: true,
                },
                {
                  title: translate(
                    `PAGES.PANEL.CAMPAIGNS.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                    }`
                  ),
                  active: false,
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="details__title">
              <h3 className="details__title__text">
                {translate(
                  `PAGES.PANEL.CAMPAIGNS.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                  }`
                )}
              </h3>
              <p className="details__title__description">
                {translate(
                  `PAGES.PANEL.CAMPAIGNS.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                  }`
                )}
              </p>
            </div>
            <AdvancedForm onSubmit={onFormSubmit}>
              <div className="details__form">
                <div className="details__title">
                  <p className="details__title__description">
                    {translate(
                      'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.TYPE_CAMPAIGN'
                    )}
                  </p>
                  <div className="details__title__description">
                    <Row>
                      <Col >
                        <AdvancedCheckbox
                          label={translate(
                            'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.IS_MAIN'
                          )}
                          value={form.isMain}
                          onChange={() => onFormChange('isMain', !form.isMain)}
                        />
                      </Col>
                      <Col >
                        <AdvancedCheckbox
                          label={translate(
                            'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.IS_ACTIVE'
                          )}
                          value={form.isActive}
                          onChange={() => onFormChange('isActive', !form.isActive)}
                        />
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('title', value)
                        }
                        value={form.title}
                        label={translate(
                          'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.TITLE'
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        label={translate(
                          'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.NAME_CAMPAIGN'
                        )}
                        value={form.name}
                        onChange={(val: string) => onFormChange('name', val)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        label={translate(
                          'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.MEDIA_URL'
                        )}
                        value={form.mediaUrl}
                        onChange={(val: string) => {
                          onFormChange('mediaUrl', val);
                          setReportLinkYoutube(val);
                        }}
                      />
                    </Col>
                  </Row>
                </div>

                {form.type !== EventType.REMINDER && (
                  <Row>
                    <Col md={6}>
                      <label>
                        {translate(
                          'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.UPLOAD_THUMB'
                        )}
                      </label>
                      <AdvancedUploader
                        onChange={setFileThumbnail}
                        thumb
                        defaultImage={
                          fileThumbnail?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              item.file?.thumbnail)?.file?.url)
                        }
                        value={
                          fileThumbnail?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              item.file?.thumbnail)?.file?.url)
                        }
                      >
                        <div>
                          <AdvancedButton
                            className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                            text={translate(
                              'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.BUTTON_UPLOAD'
                            )}
                            endIcon={<CloudUploadOutlined />}
                          />
                        </div>
                      </AdvancedUploader>
                    </Col>
                    <Col md={6}>
                      <label>
                        {translate(
                          'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.UPLOAD'
                        )}
                      </label>
                      <AdvancedUploader
                        onChange={setFile}
                        defaultImage={
                          file?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              !item.file?.thumbnail)?.file?.url)
                        }
                        value={
                          file?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              !item.file?.thumbnail)?.file?.url)
                        }
                      >
                        <div>
                          <AdvancedButton
                            className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                            text={translate(
                              'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.BUTTON_UPLOAD'
                            )}
                            endIcon={<CloudUploadOutlined />}
                          />
                        </div>
                      </AdvancedUploader>
                    </Col>
                  </Row>
                )}

                <div className="details__title">
                  <p className="details__title__description">
                    {translate(
                      'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.TITLE_DATE'
                    )}
                  </p>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string) =>
                          onFormChange('startsAtDate', value)
                        }
                        value={maskDate(form && form.startsAtDate)}
                        placeholder={translate(
                          'PAGES.PANEL.NEW_ACTIVITIES.CAMPAIGN.DETAILS.PLACEHOLDER_DATE'
                        )}
                        label={translate(
                          'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.DATE_BEGIN'
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string) =>
                          onFormChange('endsAtDate', value)
                        }
                        value={maskDate(form && form.endsAtDate)}
                        placeholder={translate(
                          'PAGES.PANEL.NEW_ACTIVITIES.CAMPAIGN.DETAILS.PLACEHOLDER_DATE'
                        )}
                        label={translate(
                          'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.DATE_END'
                        )}
                      />
                    </Col>
                  </Row>
                </div>
                
                <div className="details__title">
                  {user.me?.user.profileType === ProfileType.ADMIN && (
                    <div>
                      <Row>
                        <Col md={6}>
                          <AdvancedCheckbox
                            label={translate(
                              'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.ADD_SYNOD'
                            )}
                            value={synodCheckBox}
                            onChange={() => setSynodCheckBox(!synodCheckBox)}
                          />
                        </Col>
                        <Col md={6}>
                          {synodCheckBox && (
                            <AdvancedSelect
                              label={translate(
                                'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.SYNOD'
                              )}
                              value={form.synodId}
                              onChange={(val: string | undefined) =>
                                onFormChange('synodId', val)
                              }
                              options={synod.allSynod}
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>

                <div className="details__title">
                  <p className="details__title__description">
                    {translate(
                      'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.DESCRIPTION'
                    )}
                  </p>
                  <Row>
                    <Col md={12}>
                      <AdvancedTextInput
                        id="campaign-form"
                        value={form.description}
                        onChange={(val: string) => onFormChange('description', val)}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="details__title">
                  <p className="details__title__description">
                    {translate(
                      'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.ADDRESS'
                    )}
                  </p>
                  <Row>
                    <Col md={4}>
                      <AdvancedInput
                        onChange={(value: string | null) => {
                          onFormChange('zipcode', value);
                          if (
                            value &&
                            removeSpecialChars(value)?.length === 8
                          ) {
                            getAddress(value);
                          }
                        }}
                        value={cepMask(form && form.zipcode)}
                        label={translate(
                          'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.ZIPCODE'
                        )}
                        disabled={form.type === EventType.REMINDER}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('city', value)
                        }
                        value={form.city}
                        label={translate(
                          'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.CITY'
                        )}
                        disabled={form.type === EventType.REMINDER}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('state', value)
                        }
                        value={form.state}
                        label={translate(
                          'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.STATE'
                        )}
                        disabled={form.type === EventType.REMINDER}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('street', value)
                        }
                        value={form.street}
                        label={translate(
                          'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.STREET'
                        )}
                        disabled={form.type === EventType.REMINDER}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('number', value)
                        }
                        value={form.number}
                        label={translate(
                          'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.NUMBER'
                        )}
                        disabled={form.type === EventType.REMINDER}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('neighborhood', value)
                        }
                        value={form.neighborhood}
                        label={translate(
                          'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.NEIGHBORHOOD'
                        )}
                        disabled={form.type === EventType.REMINDER}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('complement', value)
                        }
                        value={form.complement}
                        label={translate(
                          'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.COMPLEMENT'
                        )}
                        disabled={form.type === EventType.REMINDER}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('latitude', value)
                        }
                        value={form.latitude}
                        label={translate(
                          'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.LAT'
                        )}
                        disabled={form.type === EventType.REMINDER}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('longitude', value)
                        }
                        value={form.longitude}
                        label={translate(
                          'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.LONG'
                        )}
                        disabled={form.type === EventType.REMINDER}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="details__title">
                  <p className="details__title__description">
                    {translate(
                      'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.MISCINFO'
                    )}
                  </p>
                  <Row>
                    <Col md={12}>
                      <AdvancedTextInput
                        id="campaign-form2"
                        value={form.miscInfo}
                        onChange={(val: string) => onFormChange('miscInfo', val)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AdvancedCheckbox
                        label={translate(
                          'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.ADD_SUPORT_INFO'
                        )}
                        onChange={() => setCheckBox(!checkBox)}
                        value={checkBox}
                      />
                    </Col>
                  </Row>
                  {checkBox && (
                    <Row>
                      <Col lg={8}>
                        <AdvancedInput
                          onChange={(value: string | null) =>
                            onFormChange('email', value)
                          }
                          value={form.email}
                          label={translate(
                            'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.EMAIL'
                          )}
                        />
                      </Col>
                      <Col lg={4}>
                        <AdvancedInput
                          onChange={(value: string) =>
                            onFormChange('phone', value)
                          }
                          value={phoneMask(form && form.phone)}
                          label={translate(
                            'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.PHONE'
                          )}
                        />
                      </Col>
                    </Row>
                  )}
                </div>
                <div>
                  <AdvancedUploader
                    hidePreview
                    isPdf
                    onChange={handleSetFileList}
                  >
                    <div style={{ marginTop: '35px', marginBottom: '35px' }}>
                      <AdvancedButton
                        className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                        text={translate(
                          'PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.ADD.LABELS.FILE_URL'
                        )}
                        endIcon={<CloudUploadOutlined />}
                      />
                    </div>
                  </AdvancedUploader>
                </div>

                <AdvancedDrag
                  setModalOpen={setModalOpen}
                  modalOpen={modalOpen}
                  setFileToExclude={setFileToExclude}
                  setFileList={setFileList}
                  fileList={fileList}
                  handleOk={handleOk}
                  handleCancel={handleCancel}
                />

                <div className="details__form__actions">
                  <Row className="details__form__actions__row">
                    <Col md="auto">
                      <Link
                        to={getRouteStackPath('ACTIVITIES', 'CAMPAIGNS_REPORT')}
                      >
                        <AdvancedButton
                          variant="text"
                          text={translate('SHARED.BACK')}
                          startIcon={<LeftOutlined />}
                        />
                      </Link>
                    </Col>
                    <Col md="auto" className="text-right">
                      <AdvancedButton
                        type="submit"
                        text={translate('SHARED.SAVE')}
                        endIcon={<PlusCircleOutlined />}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </AdvancedForm>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CampaignsDetails;
