import * as MessageService from "~/services/message";
import centralNavigationService from "~/services/navigation";

import { USER_DETAIL, USER_REPORT, USER_ME } from "./actionTypes";
import { decreaseLoading, increaseLoading } from "./loading";
import UserRequests from "~/repositories/user";
import SynodRequests from "~/repositories/synod";
import { ProfileType } from "@portal/enum/profileType";

export const cleanDetails = () => async (dispatch: any) => {
  dispatch({
    payload: null,
    type: USER_DETAIL,
  });
};

export const getMe = () => async (dispatch: any) => {
  dispatch(increaseLoading());

  try {
    let payload = await UserRequests.adminMe();

    if (!payload) {
      payload = { user: await UserRequests.me() };
    }

    dispatch({
      payload,
      type: USER_ME,
    });
  } catch (err: any) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getReport =
  (searchParams: advancedFilterModels.IUserAdvancedFilter) =>
  async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      const payload: models.PaginationResponse<models.User> =
        await UserRequests.getReport({
          ...searchParams,
          offset: searchParams.page - 1,
          limit: searchParams.pageSize,
        });

      dispatch({
        payload,
        type: USER_REPORT,
      });
    } catch (err: any) {
      MessageService.apiError(err.message);
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const getDetail = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.User = await UserRequests.getDetail(id);

    dispatch({
      payload,
      type: USER_DETAIL,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const create = (params: models.User) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.User = await UserRequests.create(params);

    if (payload.synodId && payload.profileType === ProfileType.SYNOD_ADMIN) {
      await SynodRequests.associateToSynod(payload.synodId, payload.id);
    }

    MessageService.success(
      "PAGES.PANEL.USER.USER.MESSAGES.SUCCESS_ADD_MESSAGE"
    );
    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const registerPhoto =
  (params: models.DigitalDocument, id: string) => async (dispatch: any) => {
    dispatch(increaseLoading());

    try {
      const payload: models.User = await UserRequests.registerPhoto(id, params);

      if (
        payload.synodId &&
        payload.profileType === ProfileType.MINISTER_USER
      ) {
        await SynodRequests.associateToSynod(payload.synodId, payload.id);
      }

      MessageService.success(
        "PAGES.PANEL.USER.USER.MESSAGES.SUCCESS_EDIT_CARD_MESSAGE"
      );
    } catch (err: any) {
      MessageService.apiError(err.message);
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const downloadPhoto = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());

  try {
    const response = await UserRequests.downloadPhoto(id);

    const url = URL.createObjectURL(new Blob([response.data]));
    const contentType = response.headers['content-type'];
    const extension = contentType.split('/')[1];

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute('download', `imagem.${extension}`);
    document.body.appendChild(link);
    link.click();

    link?.parentNode?.removeChild(link);
    URL.revokeObjectURL(url);
   
    MessageService.success(
      "Imagem baixada com sucesso!"
    );
  } catch (err: any) {
    MessageService.apiError("Ocorreu um erro ao baixar a imagem.");
  } finally {
    dispatch(decreaseLoading());
  }
};

export const remove = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await UserRequests.remove(id);
    MessageService.success(
      "PAGES.PANEL.USER.USER.MESSAGES.SUCCESS_REMOVE_MESSAGE"
    );
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const update =
  (id?: string, params?: models.User) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await UserRequests.update(id, params);

      if (params?.synodId && params?.profileType === ProfileType.SYNOD_ADMIN) {
        await SynodRequests.associateToSynod(params?.synodId, params.id);
      }

      MessageService.success(
        "PAGES.PANEL.USER.USER.MESSAGES.SUCCESS_EDIT_MESSAGE"
      );
      await centralNavigationService.back();
    } catch (err: any) {
      MessageService.apiError(err.message);
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const setSynodalView =
  (id: string | null, userData: models.User | null, profileType: number) =>
  async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      dispatch({
        payload: {
          user: {
            ...userData,
            profileType,
          },
          profileType,
          synod: {
            id,
          },
        },
        type: USER_ME,
      });
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const turnUserMinister =
  (csvFile: FormData, callback: (res: models.UploadCsvResult) => void) =>
  async (dispatch: any) => {
    dispatch(increaseLoading());
    let result;
    try {
      result = await UserRequests.turnUserMinister(csvFile);
      if (callback && typeof callback === "function") {
        callback(result);
      }
    } catch (err: any) {
      MessageService.error("APPLICATION.ERRORS.GENERIC");
    } finally {
      dispatch(decreaseLoading());
    }

    return result;
  };
