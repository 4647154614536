/* tslint:disable:object-literal-sort-keys */
const ptBr = {
  APPLICATION: {
    CURRENCY: 'BRL',
    ERRORS: {
      GENERIC: 'Parece que algo não saiu bem. Tente novamente mais tarde.',
      USER_ALREADY_EXISTS: 'Já existe um usuário com o e-mail cadastrado.',
      USER_NOT_FOUND: 'Usuário não encontrado.',
      INVALID_CREDENTIALS: 'Credenciais inválidas.',
      INVALID_EMAIL: 'Email inválido.',
      INVALID_KEY: 'Key do i18n inválida ou inexistente.',
      FORBIDDEN: 'Você não possui permissão para essa ação',
      UNAUTHORIZED: 'Acesso expirado. Faça login novamente!',
      TIMEOUT: 'Parece que algo não saiu bem. Tente novamente mais tarde.',
      EMPTY_FORM: 'Todos os campos são obrigatórios',
      EMPTY_NAME: 'O campo "nome" é requerido.',
      EMPTY_TITLE: 'O campo "título" é requerido.',
      EMPTY_SUB_TITLE: 'O campo "subtítulo" é requerido.',
      EMPTY_STATES: 'O campo "estados" é requerido.',
      EMPTY_DESCRIPTION: 'O campo "descrição" é requerido.',
      EMPTY_CONTENT: 'O campo "conteúdo" é requerido.',
      EMPTY_SCREEN_TYPE: 'O campo "botão no app" é requerido',
      EMPTY_URL: 'O campo "Navegar para url" é requerido',
      EMPTY_TO_SCREEN_TYPE: 'O campo "Navegar para" é requerido',
      EMPTY_CELLPHONE: 'O campo "telefone" está inválido.',
      EMPTY_SECOND_CELLPHONE: 'O campo "telefone Secundário" está inválido.',
      EMPTY_EMAIL: 'O campo "e-mail" é requerido.',
      EMPTY_PASSWORD: 'O campo "senha" é requerido.',
      PASSWORD_TOO_SHORT: 'A senha é muito curta.',
      MISMATCH_PASSWORD: 'As senhas não são iguais.',
      REMINDER_NAVIGATION: 'Não é possível navegar para um lembrete.',
      INVALID_DATE: 'A data inicial não pode ser maior que a data final.',
      INVALID_TIME: 'A hora inicial não pode ser maior que a hora final.',

      EMPTY_PARISH_ID: 'O campo de "paróquia" é requerido.',
      EMPTY_SYNOD_ID: 'O campo "sínodo" é requerido.',
      EMPTY_ZIPCODE: 'O campo "CEP" é requerido.',
      EMPTY_STREET: 'O campo "rua" é requerido.',
      EMPTY_NUMBER: 'O campo "número" é requerido.',
      EMPTY_NEIGHBORHOOD: 'O campo "bairro" é requerido.',
      EMPTY_CITY: 'O campo "cidade" é requerido.',
      EMPTY_STATE: 'O campo "estado" é requerido.',
      EMPTY_IMAGE: 'O campo de "imagem" é requerido.',
      EMPTY_PDF: 'O campo de "upload de pdf" é requerido.',
      EXCLUDE_PDF: 'Deseja mesmo deletar este PDF permanentemente?',
      INSTANCE: {
        REFRESH_TOKEN: 'Token expirado. Faça Login novamente!',
        LOGIN: 'Faça Login novamente!',
      },
      SUB_ACCOUNT: {
        PROD_IUGU: 'Apenas disponível para o ambiente produção',
        DEV_IUGU: 'Ação de subconta apenas em ambiente de produção',
        INVALID_PHONE: 'O campo "telefone" deve ser válido',
        INVALID_PHONE_IUGU: 'invalid_cellphone',
      }
    },
    LANG: 'pt-BR',
  },
  COMPONENTS: {
    ADVANCED_FILTER: {
      FILTER: 'Filtrar',
      CLEAN: 'limpar',
    },
    SEARCH_BAR: {
      SEARCH: {
        LABEL: 'O que deseja procurar?',
      },
    },
    ADVANCED_FILE_UPLOAD: {
      ERROR: 'Por favor insira uma imagem no formato png ou jpg',
      ERROR_MP3: 'Por favor insira um audio no formato mp3',
    },
    DATA_TABLE: {
      ROWS_PER_PAGE: 'Linhas por página:',
    },
    DATA_TABLE_ACTIONS: {
      EDIT: {
        LABEL: 'Editar',
      },
      VIEW: {
        LABEL: 'Visualizar',
      },
      DELETE: {
        LABEL: 'Deletar',
      },
      PRINT: {
        LABEL: 'Imprimir',
      },
      OPEN: {
        LABEL: 'Abrir',
      },
      HISTORY: {
        LABEL: 'Histórico',
      },
    },
    PANEL_CONTENT_TOP_BAR: {
      PAGES: {
        DASHBOARD: {
          TITLE: 'Dashboard',
          LOGOUT: 'Sair',
        },
        MARKETING: {
          TITLE: 'Marketing',
        },
        TREASURY: {
          TITLE: 'Tesouraria',
        },
      },
    },
  },
  PAGES: {
    AUTH: {
      LOGIN: {
        URL: '/',
        PAGE_TITLE: 'Entrar',
        MESSAGES: {
          LOGOUT: 'Você saiu do sistema.',
          WELCOME: 'Bem vindo(a) novamente.',
          INVALID: 'Email e/ou senha incorretos.',
        },
        ERRORS: {
          INVALID_USERNAME: 'Insira um e-mail válido.',
          INVALID_PASSWORD: 'Insira uma senha segura.',
        },
        FORM: {
          TITLE: 'ENTRAR',
          MESSAGE:
            'Utilize o seu e-mail e senha de administrador para entrar no painel administrativo.',
          EMAIL: {
            LABEL: 'E-mail',
            PLACEHOLDER: 'Seu e-mail válido',
          },
          PASSWORD: {
            LABEL: 'Senha',
            PLACEHOLDER: 'Seu senha segura',
          },
          BUTTON: {
            TEXT: 'Entrar',
          },
          BOTTOM_MESSAGE: {
            TEXT: 'Esqueceu sua senha?',
            TEXT_LINK: 'Clique aqui',
          },
        },
      },
      PASSWORD_RECOVERY: {
        URL: '/recuperar-senha',
        PAGE_TITLE: 'Recuperar senha',
        MESSAGES: {
          WELCOME: 'Bem vindo(a) novamente.',
          LOGOUT: 'Você saiu do sistema.',
          EMAIL: 'Verifique seu email',
          RECOVERY_SUCCESS: 'Senha alterada com sucesso',
        },
        FORM: {
          TITLE: 'RECUPERAR SENHA',
          MESSAGE: 'Envie um email de confirmação para trocar sua senha',

          EMAIL: {
            LABEL: 'E-mail',
            PLACEHOLDER: 'Seu e-mail válido',
          },
          BUTTON: {
            TEXT: 'Enviar e-mail',
          },
          BOTTOM_MESSAGE: {
            TEXT: 'Para voltar para o login,',
            TEXT_LINK: 'Clique aqui',
          },
          LABELS: {
            EMAIL: 'Email',
            TOKEN: 'Código de recuperação',
            PASSWORD: 'Nova senha',
            CHANGE_PASSWORD: 'ALTERAR SENHA',
          },
        },
      },
    },
    PANEL: {
      DASHBOARD: {
        SIDEBAR_TITLE: 'Dashboard',
        DETAILS: {
          WELCOME: 'Seja bem vindo(a) novamente ',
          WELCOME_NAME: 'Luan!',
          TITLE: 'Informações gerais',
          PAGE_DESCRIPTION: 'Visualize alguns dos principais indicadores',
          CARDS: {
            ONE: {
              NUMBER: '800',
              DESCRIPTION: 'Sínodos',
            },
            TWO: {
              NUMBER: '150',
              DESCRIPTION: 'Paróquias',
            },
            THREE: {
              NUMBER: '350',
              DESCRIPTION: 'Comunidades',
            },
          },
          TABLE: {
            TITLE_SYNOD: 'Sínodos',
            DESCRIPTION_SYNOD: 'Últimos Sínodos cadastrados',
            TITLE_PARISH: 'Paróquias',
            DESCRIPTION_PARISH: 'Últimas paróquias cadastradas',
            TITLE_COMMUNITY: 'Comunidades',
            DESCRIPTION_COMMUNITY: 'Últimas comunidades cadastradas',
            FIELDS: {
              DATE: {
                TITLE: 'DATA REGISTRO',
              },
              NAME: {
                TITLE: 'NOME',
              },
              CITY: {
                TITLE: 'CIDADE',
              },
              TOTAL: {
                TITLE: 'TOTAL',
              },
              ACTIONS: {
                TITLE: 'AÇÕES',
              },
            },
          },
          BARS: {
            TITLE: 'Empréstimos',
            DESCRIPTION:
              'Visualize alguns dos principais indicadores da financeira do seu banco',
            ITEMS: {
              ONE: {
                NUMBER: '1253',
                DESCRIPTION: 'Simulações de limite',
                PERCENTAGE: '75',
              },
              TWO: {
                NUMBER: '651',
                DESCRIPTION: 'Contratos efetuados',
                PERCENTAGE: '50',
              },

              THREE: {
                NUMBER: '257',
                DESCRIPTION: 'Crédito negado',
                PERCENTAGE: '25',
              },
            },
          },
        },
      },

      PANEL_CONTENT_ADD: {
        NOME: 'name',
        PARISH: 'paroquia',
        COMMUNITY: 'comunidade',
      },

      USER: {
        SIDEBAR_TITLE: 'Usuários',
        ADMIN: {
          MESSAGES: {
            SUCCESS_ADD_MESSAGE: 'Administrador adicionado com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Administrador editado com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Administrador removido com sucesso.',
            REMOVE_YOURSELF: 'Você não pode remover a si mesmo.',
          },
          REPORT: {
            TITLE: 'Administradores',
            DESCRIPTION: 'Administradores cadastrados na plataforma',
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'id',
              },
              NAME: {
                KEY: 'name',
                TITLE: 'Nome',
              },
              EMAIL: {
                KEY: 'email',
                TITLE: 'E-mail',
              },
              PROFILE_TYPE: {
                KEY: 'profileType',
                TITLE: 'Perfil',
              },
              CREATED_AT: {
                KEY: 'createdAt',
                TITLE: 'Criação',
              },
              ACTIONS: {
                KEY: 'actions',
                TITLE: 'Ações',
              },
            },
          },

          DETAILS: {
            TITLE_ADD: 'Adicionar administrador',
            TITLE_EDIT: 'Editar administrador',
            TITLE_SYNOD_IMAGE: 'Adicione a imagem de capa do sínodo',
            PAGE_DESCRIPTION_ADD:
              'Preencha os campos abaixo para adicionar um administrador',
            PAGE_DESCRIPTION_EDIT:
              'Preencha os campos abaixo para editar um administrador',
            SUCCESS_ADD_MESSAGE: 'Administrador adicionado com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Administrador editado com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Administrador removido com sucesso.',
            FORM: {
              FIELDS: {
                NAME: {
                  LABEL: 'Nome completo',
                },
                EMAIL: {
                  LABEL: 'E-mail',
                },
                CELLPHONE: {
                  LABEL: 'Telefone',
                },
                PROFILE_TYPE: {
                  LABEL: 'Tipo de perfil',
                },
                MUST_CHANGE_PASSWORD: {
                  LABEL: 'Alterar a senha?',
                },
                SELECT_SYNOD: {
                  LABEL: 'Selecionar sínodo',
                },
                PASSWORD: {
                  LABEL: 'Senha',
                },
                CONFIRM_PASSWORD: {
                  LABEL: 'Confirmar senha',
                },
              },
            },
          },
        },

        USER: {
          MESSAGES: {
            SUCCESS_ADD_MESSAGE: 'Usuário adicionado com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Usuário editado com sucesso.',
            SUCCESS_EDIT_CARD_MESSAGE: 'Carteirinha Ministerial editado com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Usuário removido com sucesso.',
          },
          REPORT: {
            TITLE: 'Usuários',
            DESCRIPTION: 'Usuários cadastrados na plataforma',
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'id',
              },
              NAME: {
                KEY: 'name',
                TITLE: 'Nome',
              },
              EMAIL: {
                KEY: 'email',
                TITLE: 'E-mail',
              },
              CREATED_AT: {
                KEY: 'createdAt',
                TITLE: 'Criação',
              },
              IS_MINISTER: {
                KEY: 'profileType',
                TITLE: 'Ministro(a)'
              },
              ACTIONS: {
                KEY: 'actions',
                TITLE: 'Ações',
              },
            },

            BUTTON: {
              TEXT: 'Atribuir Ministro',
            },

            MODAL: {
              TITLE: 'Importar planilha de usuários',
              DESCRIPTION: 'Seguindo o modelo da planilha abaixo, efetue o upload da tabela de usuários que deseja tornar ministro(a). Importante ressaltar que o não seguimento desse padrão pode implicar em erros.',
              OK_TEXT: 'Confirmar',
              CANCEL_TEXT: 'Cancelar',
              IMAGE: {
                ALT: 'Modelo de planilha a ser seguido',
              },
              IMPORT: 'Importar',
              CLEAR: 'Limpar',
              SEND_CSV: 'Enviar',
            },

            MODAL_RESULT_CSV: {
              TITLE: 'Aviso',
              USER_NOT_UNASSIGNED: 'Usuários não atribuídos',
              CLOSE_MODAL: 'Fechar',
              SUCCESS: 'Os usuários importados foram todos atribuídos',
              NAME: 'Nome',
              EMAIL: 'Email',
              TOTAL_USERS: 'Total de usuários: ',
              TOTAL_UPDATED: 'Total de usuários atualizados: ',
              TOTAL_ERRORS: 'Total de usuários com erro: ',
            },
          },

          DETAILS: {
            TITLE_ADD: 'Adicionar usuário',
            TITLE_EDIT: 'Editar usuário',
            PAGE_DESCRIPTION_ADD:
              'Preencha os campos abaixo para adicionar um usuário',
            PAGE_DESCRIPTION_EDIT:
              'Preencha os campos abaixo para editar um usuário',
            FORM: {
              FIELDS: {
                NAME: {
                  LABEL: 'Nome completo',
                },
                EMAIL: {
                  LABEL: 'E-mail',
                },
                CELLPHONE: {
                  LABEL: 'Telefone',
                },
                MUST_CHANGE_PASSWORD: {
                  LABEL: 'Alterar a senha?',
                },
                PASSWORD: {
                  LABEL: 'Senha',
                },
                CONFIRM_PASSWORD: {
                  LABEL: 'Confirmar senha',
                },
              },
            },
          },
        },

        SYNOD: {
          MESSAGES: {
            SUCCESS_ADD_MESSAGE: 'Sínodo adicionado com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Sínodo editado com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Sínodo removido com sucesso.',
          },
          REPORT: {
            TITLE: 'Sínodos',
            DESCRIPTION: 'Sínodos cadastrados na plataforma',
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'id',
              },
              NAME: {
                KEY: 'name',
                TITLE: 'Nome',
              },
              CREATED_AT: {
                KEY: 'createdAt',
                TITLE: 'Criação',
              },
              ACTIONS: {
                KEY: 'actions',
                TITLE: 'Ações',
              },
            },
          },

          DETAILS: {
            TITLE_ADD: 'Adicionar sínodo',
            TITLE_EDIT: 'Editar sínodo',
            PAGE_DESCRIPTION_ADD:
              'Preencha os campos abaixo para adicionar um sínodo',
            PAGE_DESCRIPTION_EDIT:
              'Preencha os campos abaixo para editar um sínodo',
            FORM: {
              LABELS: {
                SECOND_SECTOR: 'Detalhes de pagamento',
              },
              FIELDS: {
                NAME: {
                  LABEL: 'Nome',
                },
                STATES: {
                  LABEL: 'Estados',
                },
                DOCUMENT: {
                  LABEL: 'CPF/CNPJ *',
                },
                CELLPHONE: {
                  LABEL: 'Telefone *',
                },
                AUTOWITHDRAW: {
                  LABEL: 'Retirada automática *',
                },
                RESPONSIBLENAME: {
                  LABEL: 'Nome do responsável *',
                },
                BUSINESSDESCRIPTION: {
                  LABEL: 'Descrição do negócio *',
                },
                BANKNAME: {
                  LABEL: 'Banco *',
                },
                BANKAGENCY: {
                  LABEL: 'Agência bancária *',
                },
                BANKACCOUNT: {
                  LABEL: 'Conta bancária *',
                },
                BANKACCOUNTTYPE: {
                  LABEL: 'Tipo de conta *',
                },
                SAVINGS: {
                  LABEL: 'POUPANÇA',
                },
                CHECKING: {
                  LABEL: 'CORRENTE',
                },
              },
            },
          },
        },

        PARISH: {
          MESSAGES: {
            SUCCESS_ADD_MESSAGE: 'Paróquia adicionada com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Paróquia editada com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Paróquia removida com sucesso.',
          },
          REPORT: {
            TITLE: 'Paróquias',
            DESCRIPTION: 'Paróquias cadastradas na plataforma',
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'id',
              },
              NAME: {
                KEY: 'name',
                TITLE: 'Nome',
              },
              CREATED_AT: {
                KEY: 'createdAt',
                TITLE: 'Criação',
              },
              CITY: {
                KEY: 'address',
                TITLE: 'Cidade',
              },
              ACTIONS: {
                KEY: 'actions',
                TITLE: 'Ações',
              },
            },
          },

          DETAILS: {
            TITLE_ADD: 'Adicionar paróquia',
            TITLE_EDIT: 'Editar paróquia',
            PAGE_DESCRIPTION_ADD:
              'Preencha os campos abaixo para adicionar uma paróquia',
            PAGE_DESCRIPTION_EDIT:
              'Preencha os campos abaixo para editar uma paróquia',
            FORM: {
              ERRORS: {
                MEDIA_URL: 'Insira um vídeo do youtube válido',
              },
              SUBTITLES: {
                WHERE_ARE_WE: {
                  LABEL: 'Onde estamos',
                },
              },
              TITLES: {
                ADDRESS: 'Endereço',
                PREACHING_POINTS: 'Pontos de pregação',
              },
              FIELDS: {
                NAME: {
                  LABEL: 'Nome *',
                },
                EMAIL: {
                  LABEL: 'Email *',
                },
                PHONE: {
                  LABEL: 'Telefone *',
                },
                SECOND_PHONE: {
                  LABEL: 'Telefone Secundário',
                },
                FACEBOOK: {
                  LABEL: 'Facebook',
                },
                INSTAGRAM: {
                  LABEL: 'Instagram',
                  PLACEHOLDER: '@seu_usuario',
                },
                SYNOD: {
                  LABEL: 'Sínodo *',
                },
                ZIPCODE: {
                  LABEL: 'CEP *',
                },
                STREET: {
                  LABEL: 'Rua *',
                },
                NUMBER: {
                  LABEL: 'Número *',
                },
                NEIGHBORHOOD: {
                  LABEL: 'Bairro',
                },
                CITY: {
                  LABEL: 'Cidade *',
                },
                STATE: {
                  LABEL: 'Estado *',
                },
                COMPLEMENT: {
                  LABEL: 'Complemento',
                },
                LONGITUDE: {
                  LABEL: 'Longitude',
                },
                LATITUDE: {
                  LABEL: 'Latitude',
                },
                WHERE_ARE_WE: {
                  TITLE: {
                    LABEL: 'Título',
                  },
                  DESCRIPTION: {
                    LABEL: 'Descrição',
                  },
                  MEDIA_URL: {
                    LABEL: 'Link do youtube',
                  },
                  UPLOAD: {
                    LABEL: 'Adicione a imagem da paróquia',
                  },
                  BUTTON_UPLOAD: 'Adicionar imagem',
                },
              },
            },
          },
        },

        LEADERS: {
          MESSAGES: {
            SUCCESS_ADD_MESSAGE: 'Liderança adicionada com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Liderança editada com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Liderança removida com sucesso.',
          },
          REPORT: {
            TITLE: 'Lideranças',
            DESCRIPTION: 'Lideranças cadastradas na plataforma',
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'id',
              },
              NAME: {
                KEY: 'name',
                TITLE: 'Nome',
              },
              DESCRIPTION: {
                KEY: 'roleDescription',
                TITLE: 'Função',
              },
              TYPE: {
                KEY: 'roleType',
                TITLE: 'Perfil',
              },
              CREATED_AT: {
                KEY: 'createdAt',
                TITLE: 'Criação',
              },
              ACTIONS: {
                KEY: 'actions',
                TITLE: 'Ações',
              },
            },
          },

          DETAILS: {
            TITLE_ADD: 'Adicionar liderança',
            TITLE_EDIT: 'Editar liderança',
            PAGE_DESCRIPTION_ADD:
              'Preencha os campos abaixo para adicionar uma liderança',
            PAGE_DESCRIPTION_EDIT:
              'Preencha os campos abaixo para editar uma liderança',
            FORM: {
              FIELDS: {
                ADD_COUNCIL: {
                  LABEL: 'Adicionar como Conselhos e Coordenações?',
                },
                NAME: {
                  LABEL: 'Nome',
                },
                CAM: {
                  LABEL: 'CAM',
                },
                ROLE_DESCRIPTION: {
                  LABEL: 'Descrição da função',
                },
                LEADER_ROLE: {
                  LABEL: 'Perfil',
                },
                SYNOD_ID: {
                  LABEL: 'Sínodo',
                },
              },
            },
          },
        },

        COMMUNITY: {
          MESSAGES: {
            SUCCESS_ADD_MESSAGE: 'Comunidade adicionada com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Comunidade editada com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Comunidade removido com sucesso.',
          },
          REPORT: {
            TITLE: 'Comunidades',
            DESCRIPTION: 'Comunidades cadastradas na plataforma',
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'id',
              },
              NAME: {
                KEY: 'name',
                TITLE: 'Nome',
              },
              PARISH: {
                KEY: 'parish.name',
                TITLE: 'Paróquia',
              },
              CITY: {
                KEY: 'address.city',
                TITLE: 'Cidade',
              },
              STATE: {
                KEY: 'address.state',
                TITLE: 'Estado',
              },
              CREATED_AT: {
                KEY: 'createdAt',
                TITLE: 'Criação',
              },
              ACTIONS: {
                KEY: 'actions',
                TITLE: 'Ações',
              },
            },
          },

          DETAILS: {
            TITLE_ADD: 'Adicionar Comunidade',
            TITLE_EDIT: 'Editar Comunidade',
            PAGE_DESCRIPTION_ADD:
              'Preencha os campos abaixo para adicionar um Comunidade',
            PAGE_DESCRIPTION_EDIT:
              'Preencha os campos abaixo para editar um Comunidade',
            FORM: {
              ERRORS: {
                MEDIA_URL: 'Insira um vídeo do youtube válido',
              },
              SUBTITLES: {
                WHERE_ARE_WE: {
                  LABEL: 'Onde estamos',
                },
              },
              FIELDS: {
                NAME: {
                  LABEL: 'Nome *',
                },
                PARISH: {
                  LABEL: 'Paróquia',
                },
                ZIPCODE: {
                  LABEL: 'CEP *',
                },
                STREET: {
                  LABEL: 'Rua *',
                },
                NUMBER: {
                  LABEL: 'Número *',
                },
                NEIGHBORHOOD: {
                  LABEL: 'Bairro',
                },
                CITY: {
                  LABEL: 'Cidade *',
                },
                STATE: {
                  LABEL: 'Estado *',
                },
                COMPLEMENT: {
                  LABEL: 'Complemento',
                },
                LONGITUDE: {
                  LABEL: 'Longitude',
                },
                LATITUDE: {
                  LABEL: 'Latitude',
                },
                WHERE_ARE_WE: {
                  TITLE: {
                    LABEL: 'Título',
                  },
                  DESCRIPTION: {
                    LABEL: 'Descrição',
                  },
                  MEDIA_URL: {
                    LABEL: 'Link do youtube',
                  },
                  UPLOAD: {
                    LABEL: 'Adicione a imagem da comunidade',
                  },
                  BUTTON_UPLOAD: 'Adicionar imagem',
                },
              },
            },
          },
        },
      },

      NEW_ACTIVITIES: {
        SIDEBAR_TITLE: 'ATIVIDADES',
        OFFER: {
          MESSAGES: {
            SUCCESS_ADD_MESSAGE: 'Oferta adicionada com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Oferta editada com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Oferta removida com sucesso.',
          },
        },
        MESSAGES: {
          NOTICE: {
            SUCCESS_ADD_MESSAGE: 'Mensagem adicionado com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Mensagem editado com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Mensagem removido com sucesso.',
          },
          REPORT: {
            TITLE: 'Mensagens',
            DESCRIPTION: 'Mensagens cadastrados na plataforma',
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'Id',
              },
              TITLE: {
                KEY: 'title',
                TITLE: 'Título',
              },
              DESCRIPTION: {
                KEY: 'description',
                TITLE: 'Descrição',
              },
              SCOPE: {
                KEY: 'scope',
                TITLE: 'Perfil',
              },
              TYPE: {
                KEY: 'type',
                TITLE: 'Tipo da mensagem',
              },
              START_EVENT: {
                KEY: 'startsAtDate',
                TITLE: 'Data da mensagem',
              },
              PHONE: {
                KEY: 'phone',
                TITLE: 'Telefone',
              },
              STREET: {
                KEY: 'address.street',
                TITLE: 'Rua',
              },
              CITY: {
                KEY: 'address.city',
                TITLE: 'Cidade',
              },
              STATE: {
                KEY: 'address.state',
                TITLE: 'Estado',
              },
              NUMBER: {
                KEY: 'address.number',
                TITLE: 'Número',
              },
              ZIPCODE: {
                KEY: 'address.zipcode',
                TITLE: 'CEP',
              },
            },
          },

          DETAILS: {
            TITLE_ADD: 'Adicionar mensagem',
            TITLE_EDIT: 'Editar mensagem',
            PAGE_DESCRIPTION_ADD:
              'Preencha os campos abaixo para adicionar uma mensagem',
            PAGE_DESCRIPTION_EDIT:
              'Preencha os campos abaixo para editar uma mensagem',
            SUCCESS_ADD_MESSAGE: 'Mensagem adicionado com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Mensagem editado com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Mensagem removido com sucesso.',
            PLACEHOLDER_DATE: 'dd/mm/aaaa',
            PLACEHOLDER_TIME: 'hh:mm',
            ERRORS: {
              IMAGE: 'Insira a imagem da mensagem',
              INIT_DATE: 'Data inicial inválida',
              END_DATE: 'Data final inválida',
              DATE: 'Insira as datas do mensagem!',
              MESSAGE: 'Insira sobre a mensagem corretamente!',
              DESCRIPTION: 'Insira uma descrição para a mensagem',
              TITLE: 'Insira um título para a mensagem',
              NAME: 'Insira um nome para a mensagem',
              TYPE: 'Insira um tipo para a mensagem',
              MEDIA_URL: 'Insira um link de midia',
            },
          },
        },
        CAMPAIGN: {
          MESSAGES: {
            SUCCESS_ADD_MESSAGE: 'Campanha adicionada com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Campanha editada com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Campanha removida com sucesso.',
          },
          DETAILS: {
            PLACEHOLDER_DATE: 'dd/mm/aaaa',
            PLACEHOLDER_TIME: 'hh:mm',
            ERRORS: {
              IMAGE: 'Insira a imagem da campanha',
              INIT_DATE: 'Data inicial inválida',
              END_DATE: 'Data final inválida',
              DATE: 'Insira as datas da campanha!',
              TIME: 'Insira o horário da campanha!',
              CAMPAIGN: 'Insira sobre a campanha corretamente!',
              DESCRIPTION: 'Insira uma descrição para a campanha',
              TITLE: 'Insira um título para a campanha',
              NAME: 'Insira um nome para a campanha',
              TYPE: 'Insira um tipo para a campanha',
              MEDIA_URL: 'Insira um link do youtube',
            },
          },
        },
        OCCUPATION_AREA: {
          MESSAGES: {
            SUCCESS_ADD_MESSAGE: 'Área de atuação adicionada com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Área de atuação editada com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Área de atuação removida com sucesso.',
          },
        },
        SCHEDULE: {
          MESSAGES: {
            SUCCESS_ADD_MESSAGE: 'Evento adicionado com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Evento editado com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Evento removido com sucesso.',
          },
          REPORT: {
            TITLE: 'Agenda & Eventos',
            DESCRIPTION: 'Agendas e eventos cadastrados na plataforma',
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'Id',
              },
              TITLE: {
                KEY: 'title',
                TITLE: 'Título',
              },
              DESCRIPTION: {
                KEY: 'description',
                TITLE: 'Descrição',
              },
              SCOPE: {
                KEY: 'scope',
                TITLE: 'Perfil',
              },
              TYPE: {
                KEY: 'type',
                TITLE: 'Tipo do evento',
              },
              START_EVENT: {
                KEY: 'startsAtDate',
                TITLE: 'Data do evento',
              },
              PHONE: {
                KEY: 'phone',
                TITLE: 'Telefone',
              },
              STREET: {
                KEY: 'address.street',
                TITLE: 'Rua',
              },
              CITY: {
                KEY: 'address.city',
                TITLE: 'Cidade',
              },
              STATE: {
                KEY: 'address.state',
                TITLE: 'Estado',
              },
              NUMBER: {
                KEY: 'address.number',
                TITLE: 'Número',
              },
              ZIPCODE: {
                KEY: 'address.zipcode',
                TITLE: 'CEP',
              },
            },
          },

          DETAILS: {
            TITLE_ADD: 'Adicionar evento',
            TITLE_EDIT: 'Editar evento',
            PAGE_DESCRIPTION_ADD:
              'Preencha os campos abaixo para adicionar um evento',
            PAGE_DESCRIPTION_EDIT:
              'Preencha os campos abaixo para editar um evento',
            SUCCESS_ADD_MESSAGE: 'Evento adicionado com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Evento editado com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Evento removido com sucesso.',
            PLACEHOLDER_DATE: '11/11/2021',
            PLACEHOLDER_TIME: '14:00',
            ERRORS: {
              IMAGE: 'Insira a imagem do evento',
              INIT_DATE: 'Data inicial inválida',
              END_DATE: 'Data final inválida',
              DATE: 'Insira as datas do evento!',
              HOUR: 'O horário do evento é inválido!',
              EVENT: 'Insira sobre o evento corretamente!',
              DESCRIPTION: 'Insira uma descrição para o evento',
              TITLE: 'Insira um título para o evento',
              NAME: 'Insira um nome para o evento',
              TYPE: 'Insira um tipo para o evento',
              MEDIA_URL: 'Insira um link do youtube',
            },
          },
        },

        PDF: {
          REPORT: {
            TITLE: 'PDF da Agenda',
            TITLE_OFFER: 'PDF da Oferta',
            TITLE_SINOD: 'PDF de Sínodo',
            DESCRIPTION: 'PDFs da Agenda cadastrados na plataforma',
            DESCRIPTION_OFFER: 'PDFs das Ofertas cadastradas na plataforma',
            DESCRIPTION_SINOD: 'PDFs dos sínodos cadastrados na plataforma',
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'Id',
              },
              TITLE: {
                KEY: 'label',
                TITLE: 'Mês/Ano',
              },
              SYNOD: {
                KEY: 'synod',
                TITLE: 'Sínodo',
              },
              UPDATE_DATE: {
                KEY: 'date',
                TITLE: 'Data',
              },
              ACTIONS: {
                KEY: 'actions',
                TITLE: 'Ações',
              },
            },
          },
          DETAILS: {
            BUTTON_LINK: 'VER PDF',
            EDIT_PDF: 'Alterar PDF',
            TITLE: 'Notícias',
            PAGE_TITLE: 'PDF da agenda',
            PAGE_OFFER_TITLE: 'PDF da oferta',
            PAGE_SINOD_TITLE: 'PDF dos sínodos',
            PAGE_DESCRIPTION_EDIT: 'Editar o PDF',
            PAGE_DESCRIPTION_ADD: 'Adicionar PDF',
            ADD: {
              LABELS: {
                TITLE: 'Mês/Ano',
                FILE_URL: 'Upload do PDF',
                DATE: 'Data',
                BUTTON: 'Salvar',
              },
              PLACEHOLDERS: {
                DATE: '11/11/2021',
                LABEL: 'Ex: JANEIRO/2021',
              },
            },
            TITLE_ADD: 'Adicionar PDF',
            TITLE_EDIT: 'Editar PDF',
            SUCCESS_ADD_MESSAGE: 'PDF adicionado com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'PDF editado com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'PDF removido com sucesso.',
            PLACEHOLDER_DATE: '11/11/2021',
            PLACEHOLDER_TIME: '14:00',
            ERRORS: {
              EMPTY_UPLOAD: 'Insira um arquivo no formato PDF',
              EMPTY_LABEL: 'Insira um o mês/ano do pdf',
              EMPTY_DATE: 'Insira uma data válida',
            },
          },
        },
      },

      WORSHIP: {
        TITLE: 'Culto & Oração',
        FORM: {
          URL: 'Insira a URL da imagem a ser exibida no aplicativo',
          TITLE: 'Insira o título da mensagem',
          DESCRIPTION: 'Insira a mensagem',
          BUTTON: 'Salvar',
          SUCCESS_EDIT_MESSAGE: 'Culto atualizado com sucesso!',
          SUCCESS_ADD_MESSAGE: 'Culto adicionado com sucesso!',
          SUCCESS_REMOVE_MESSAGE: 'Culto removido com sucesso.',
        },
      },

      CULT: {
        ROUTE: {
          NAME: 'WORSHIP',
          REPORT: 'CULT',
          DETAILS: 'DETALHES',
        },
        TITLE: 'CULT',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Culto',
          PAGE_TITLE: 'Culto',
          PAGE_DESCRIPTION: 'Cultos cadastrados na plataforma',
          DESCRIPTION_INPUT: 'Descrição da página de culto no app',
          TABLE: {
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'Identificador',
              },
              TITLE: {
                KEY: 'title',
                TITLE: 'Título',
              },
              FROM_DATE: {
                KEY: 'fromDate',
                TITLE: 'Data de ínicio',
              },
              UNTIL_DATE: {
                KEY: 'untilDate',
                TITLE: 'Data de término',
              },
              ACTIONS: {
                KEY: 'actions',
                TITLE: 'Ações',
              },
            },
          },
        },
        DETAILS: {
          TITLE_ADD: 'Adicionar Culto',
          TITLE_EDIT: 'Editar Culto',
          PAGE_DESCRIPTION_ADD:
            'Preencha os campos abaixo para adicionar um culto',
          PAGE_DESCRIPTION_EDIT:
            'Preencha os campos abaixo para editar o culto',

          ERRORS: {
            TITLE: 'Preencha o campo título',
            FROM_DATE: 'Preencha o campo data de ínicio',
            UNTIL_DATE: 'Preencha o campo data de término',
            DESCRIPTION: 'Preencha o campo descrição',
            HEADER: 'Preencha o campo título secundário',
            VIDEO_URL: 'Insira um vídeo do youtube válido',
          },

          ADD: {
            LABELS: {
              TITLE: 'Título',
              FROM_DATE: 'Data de ínicio',
              UNTIL_DATE: 'Data de término',
              DESCRIPTION: 'Descrição',
              HEADER: 'Título secundário',
              VIDEO_URL: 'Link do youtube',
              IMAGE_URL: 'Link da imagem',
              UPLOAD_IMAGE: 'Upload de imagem',
            },
          },
        },
      },

      LITURGY: {
        ROUTE: {
          NAME: 'WORSHIP',
          REPORT: 'LITURGY',
          DETAILS: 'DETALHES',
        },
        FORM: {
          SUCCESS_ADD_MESSAGE: 'Liturgia adicionada com sucesso.',
          SUCCESS_EDIT_MESSAGE: 'Liturgia editada com sucesso.',
          SUCCESS_REMOVE_MESSAGE: 'Liturgia deletada com sucesso.',
        },
        TITLE: 'LITURGY',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Liturgia',
          PAGE_TITLE: 'Liturgia',
          PAGE_DESCRIPTION: 'Liturgias cadastradas na plataforma',
          TABLE: {
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'Identificador',
              },
              TITLE: {
                KEY: 'title',
                TITLE: 'Título',
              },
              CONTENT: {
                KEY: 'content',
                TITLE: 'Subtítulo',
              },
              ACTIONS: {
                KEY: 'actions',
                TITLE: 'Ações',
              },
            },
          },
        },
        DETAILS: {
          TITLE_ADD: 'Adicionar Liturgia',
          TITLE_EDIT: 'Editar Liturgia',
          PAGE_DESCRIPTION_ADD:
            'Preencha os campos abaixo para adicionar uma liturgia',
          PAGE_DESCRIPTION_EDIT:
            'Preencha os campos abaixo para editar a liturgia',

          ERRORS: {
            TITLE: 'Preencha o campo título',
          },

          ADD: {
            LABELS: {
              TITLE: 'Título',
              NUMBER: 'Númeração',
              CONTENT: 'Sub-título',
              REFERENCE: 'Referência',
              AUTHOR: 'Autor',
              TRANSLATION: 'Tradução',
              FILE: 'Arquivo',
            },
          },
        },
      },

      PRAYER: {
        ROUTE: {
          NAME: 'WORSHIP',
          REPORT: 'PRAYER',
          DETAILS: 'DETALHES',
        },
        FORM: {
          SUCCESS_ADD_MESSAGE: 'Oração adicionada com sucesso.',
          SUCCESS_EDIT_MESSAGE: 'Oração editada com sucesso.',
          SUCCESS_REMOVE_MESSAGE: 'Oração deletada com sucesso.',
        },
        TITLE: 'PRAYER',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Oração',
          PAGE_TITLE: 'Oração',
          PAGE_DESCRIPTION: 'Orações cadastradas na plataforma',
          DESCRIPTION_INPUT: 'Descrição da página de orações no app',
          TABLE: {
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'Identificador',
              },
              TITLE: {
                KEY: 'title',
                TITLE: 'Título',
              },
              NUMBER: {
                KEY: 'number',
                TITLE: 'Número',
              },
              AUTHOR: {
                KEY: 'author',
                TITLE: 'Autor',
              },
              REFERENCE: {
                KEY: 'reference',
                TITLE: 'Referência',
              },
              ACTIONS: {
                KEY: 'actions',
                TITLE: 'Ações',
              },
            },
          },
        },
        DETAILS: {
          TITLE_ADD: 'Adicionar Oração',
          TITLE_EDIT: 'Editar Oração',
          PAGE_DESCRIPTION_ADD:
            'Preencha os campos abaixo para adicionar uma oração',
          PAGE_DESCRIPTION_EDIT:
            'Preencha os campos abaixo para editar a oração',

          ERRORS: {
            TITLE: 'Preencha o campo título',
          },

          ADD: {
            LABELS: {
              TITLE: 'Título',
              NUMBER: 'Númeração',
              CONTENT: 'Conteúdo',
              REFERENCE: 'Referência',
              AUTHOR: 'Autor',
              TRANSLATION: 'Tradução',
              FILE: 'Arquivo',
            },
          },
        },
      },

      INSTITUTIONAL: {
        TITLE: 'USUÁRIOS',
        FORM: {
          URL: 'Insira a URL do vídeo a ser postado no aplicativo',
          TITLE: 'Insira o título da mensagem',
          DESCRIPTION: 'Insira a mensagem',
          OTHER_INFO: 'Outras informações',
          PARTICIPATE_TITLE: 'Titulo para como participar',
          PARTICIPATE: 'Como participar',
          HISTORY_TITLE: 'Título para nossa história',
          HISTORY: 'Nossa história',
          MISSION_TITLE: 'Título para nossa missão',
          MISSION: 'Nossa missão',
          BUTTON: 'Salvar',
          SUCCESS_EDIT_MESSAGE: 'Instituição atualizada com sucesso!',
        },
      },
      IECLB: {
        ROUTE: {
          NAME: 'INSTITUCIONAL',
          REPORT: 'IECLB',
          DETAILS: 'DETALHES',
        },
        TITLE: 'IECLB',
        REPORT: {
          TITLE: 'Mensagens',
          PAGE_TITLE: 'Mensagem IECLB',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
        },
      },
      ORGANIZATION: {
        ROUTE: {
          NAME: 'INSTITUCIONAL',
          REPORT: 'ORGANIZATION',
          DETAILS: 'DETALHES',
        },
        TITLE: 'Organização',
        REPORT: {
          TITLE: 'Mensagens',
          PAGE_TITLE: 'Mensagem para organização',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
        },
      },
      ECUMENISM: {
        ROUTE: {
          NAME: 'INSTITUCIONAL',
          REPORT: 'ECUMENISM',
          DETAILS: 'DETALHES',
        },
        TITLE: 'Ecumenismo',
        REPORT: {
          TITLE: 'Mensagens',
          PAGE_TITLE: 'Mensagem para ecumenismo',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
        },
      },
      INSTITUTION: {
        ROUTE: {
          NAME: 'INSTITUCIONAL',
          REPORT: 'INSTITUTION',
          DETAILS: 'DETALHES',
        },
        TITLE: 'Instituições',
        REPORT: {
          TITLE: 'Mensagens',
          PAGE_TITLE: 'Mensagem para instituições',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
        },
      },

      ACTIVITIES: {
        TITLE: 'ATIVIDADES',
        SIDEBAR_TITLE: 'ATIVIDADES',
        FORM: {
          URL: 'Insira a URL do vídeo a ser postado no aplicativo',
          TITLE: 'Insira o título da mensagem',
          DESCRIPTION: 'Insira a mensagem',
          BUTTON: 'Salvar',
        },
      },
      SCHEDULE: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'SCHEDULE',
          DETAILS: 'DETALHES',
          URL_ADD: '/atividades/agenda/detalhes/:id?',
        },
        TITLE: 'Agenda & eventos',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Agenda & eventos',
          PAGE_TITLE: 'Agenda & eventos',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
          ADVANCED_FILTER: {
            TITLE: 'Título',
            DATE_BEGIN: 'Data de início',
            DATE_END: 'Data final',
            EXHIBITION_DATE: 'Data de exibição',
            CITY: 'Cidade',
          },
          TABLE: {
            FIELD_TITLE: 'title',
            TITLE: 'Título',
            FIELD_DATA_BEGIN: 'dataBegin',
            DATA_BEGIN: 'Data inicial',
            FIELD_DATA_END: 'dataEnd',
            DATA_END: 'Data final',
            FIELD_TYPE: 'type',
            TYPE: 'Tipo de Evento',
            FIELD_CITY: 'city',
            CITY: 'Cidade',
          },
        },
      },
      SCHEDULE_DETAILS: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'SCHEDULE_DETAILS',
          DETAILS: 'DETALHES',
        },
        TITLE: 'Agenda & eventos',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Agenda & eventos',
          PAGE_TITLE: 'Agenda & eventos',
          PAGE_DESCRIPTION:
            'cadatre o seu Evento abaixo preenchendo as informações!',
          ADVANCED_FILTER: {
            TITLE: 'Título',
            DATE_BEGIN: 'Data de início:',
            DATE_END: 'Data final:',
            CITY: 'Cidade',
          },
          ADD: {
            LABELS: {
              UPLOAD: 'Adicione a imagem para os detalhes do evento',
              UPLOAD_THUMB: 'Adicione a imagem da listagem do evento',
              DATE_BEGIN: 'Data de início:',
              DATE_END: 'Data final:',
              TITLE_TIME: 'Horário do Evento:',
              TYPE_EVENT: 'Sobre o evento',
              EVENT: 'Tipo de evento',
              ADD_SYNOD: 'Deseja adicionar para um sínodo?',
              ADD_LOCAL: 'Deseja adicionar como local?',
              IS_MAIN: 'Oferta principal?',
              SYNOD: 'Sínodo',
              NAME_EVENT: 'Nome do evento',
              TITLE_DATE: 'Data do evento:',
              TIME_BEGIN: 'Hora de início:',
              TIME_END: 'Hora Final:',
              TITLE: 'Título',
              DESCRIPTION: 'Descrição',
              ADDRESS: 'Localização',
              STREET: 'Rua',
              NUMBER: 'Número',
              NEIGHBORHOOD: 'Bairro',
              ZIPCODE: 'CEP',
              CITY: 'Cidade',
              STATE: 'Estado',
              COMPLEMENT: 'Complemento',
              LAT: 'Latitude',
              LONG: 'Longitude',
              MISCINFO: 'Outras informações',
              ADD_SUPORT_INFO: 'Deseja adicionar informações de contato?',
              MEDIA_URL: 'Link do Youtube',
              EMAIL: 'E-mail',
              PHONE: 'Telefone',
              BUTTON: 'Salvar',
              BUTTON_UPLOAD: 'Adicionar imagem',
              BUTTON_UPLOAD_MULTIPLE: 'Adicionar imagens',
            },
            ERRORS: {
              MEDIA_URL: 'Insira um vídeo do youtube válido',
              INVALID_DATE: 'Data inválida',
              INVALID_HOUR: 'Horário inválido',
            },
          },
        },
      },

      MESSAGES: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'MESSAGES',
          DETAILS: 'DETALHES',
          URL_ADD: '/atividades/mensagens/detalhes/:id?',
        },
        TITLE: 'Mensagens',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Mensagens',
          PAGE_TITLE: 'Mensagens',
          PAGE_DESCRIPTION: 'Preencha com os campos com os filtros indicadas:',
          ADVANCED_FILTER: {
            TITLE: 'Título',
            DATE_BEGIN: 'Data de início',
            DATE_END: 'Data final',
            CITY: 'Cidade',
          },
          TABLE: {
            FIELD_TITLE: 'title',
            TITLE: 'Título',
            FIELD_DATA_BEGIN: 'dataBegin',
            DATA_BEGIN: 'Data inicial',
            FIELD_DATA_END: 'dataEnd',
            DATA_END: 'Data final',
            FIELD_TYPE: 'type',
            TYPE: 'Tipo de Evento',
            FIELD_CITY: 'city',
            CITY: 'Cidade',
          },
        },
        DETAILS: {
          TITLE_ADD: 'Adicionar Mensagem',
          TITLE_EDIT: 'Editar Mensagem',
          PAGE_DESCRIPTION_ADD:
            'Preencha os campos abaixo para adicionar uma mensagem',
          PAGE_DESCRIPTION_EDIT:
            'Preencha os campos abaixo para editar a mensagem',
        },
      },
      MESSAGES_DETAILS: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'MESSAGES_DETAILS',
          DETAILS: 'DETALHES',
        },
        TITLE: 'Mensagens',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Mensagens',
          PAGE_TITLE: 'Mensagens',
          PAGE_DESCRIPTION:
            'cadastre sua mensagem abaixo preenchendo as informações!',
          ADVANCED_FILTER: {
            TITLE: 'Título',
            DATE_BEGIN: 'Data de início:',
            DATE_END: 'Data final:',
            CITY: 'Cidade',
          },
          ADD: {
            LABELS: {
              UPLOAD: 'Adicione a imagem para os detalhes da mensagem',
              UPLOAD_THUMB: 'Adicione a imagem para a listagem da mensagem',
              DATE: 'Data',
              TITLE_TIME: 'Horário da mensagem:',
              TYPE_MESSAGE: 'Sobre a mensagem',
              ADD_SYNOD: 'Deseja adicionar para um sínodo?',
              IS_MAIN: 'Oferta principal?',
              SYNOD: 'Sínodo',
              NAME_MESSAGE: 'Nome da mensagem',
              TITLE_DATE: 'Data da mensagem:',
              TIME_BEGIN: 'Hora de início:',
              TIME_END: 'Hora Final:',
              TITLE: 'Título',
              DESCRIPTION: 'Descrição',
              MEDIA_URL: 'Link da mídia',
              SECONDARY_MEDIA_URL: 'Link do youtube',
              BUTTON: 'Salvar',
              BUTTON_UPLOAD: 'Adicionar imagem',
              BUTTON_UPLOAD_MEDIA: 'Adicionar áudio',
              BUTTON_UPLOAD_MULTIPLE: 'Adicionar imagens',
              MEDIA: 'Adicione o áudio da mensagem',
            },
            ERRORS: {
              SECONDARY_MEDIA_URL: 'Insira um vídeo do youtube válido',
              INVALID_DATE: 'Data inválida',
              INVALID_HOUR: 'Horário inválido',
            },
          },
        },
      },

      NEWS: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'NEWS',
          DETAILS: 'DETALHES',
          URL_ADD: '/atividades/noticia/detalhes/:id?',
        },
        MESSAGES: {
          SUCCESS_ADD_MESSAGE: 'Notícia adicionada com sucesso.',
          SUCCESS_EDIT_MESSAGE: 'Notícia editada com sucesso.',
          SUCCESS_DELETE_MESSAGE: 'Notícia deletada com sucesso.',
        },
        TITLE: 'Notícias',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Notícias',
          PAGE_TITLE: 'Notícias',
          PAGE_DESCRIPTION: 'Notícias cadastradas na plataforma',
          ADVANCED_FILTER: {
            TITLE: 'Título',
            DATE: 'Data',
          },
          ADD: {
            LABELS: {
              TITLE: 'Título',
              DESCRIPTION: 'Descrição',
              FILE_URL: 'Faça o upload de um arquivo',
              DATE: 'Data',
              BUTTON: 'Salvar',
            },
          },
          TABLE: {
            FIELD_TITLE: 'title',
            TITLE: 'Título',
            FIELD_DATA: 'data',
            DATA: 'Data',
            FIELD_TYPE: 'type',
            TYPE: 'Tipo de Evento',
          },
        },
        DETAILS: {
          TITLE_ADD: 'Adicionar notícia',
          TITLE_EDIT: 'Editar notícia',
          PAGE_DESCRIPTION_ADD:
            'Preencha os campos abaixo para adicionar uma notícia',
          PAGE_DESCRIPTION_EDIT:
            'Preencha os campos abaixo para editar a notícia',
        },
        FIELDS: {
          ID: {
            KEY: 'id',
            TITLE: 'ID',
          },
          TITLE: {
            KEY: 'ad.title',
            TITLE: 'Título',
          },
          SYNOD: {
            KEY: 'ad.synod',
            TITLE: 'Perfil',
          },
          CREATED_AT: {
            KEY: 'createdAt',
            TITLE: 'Data',
          },
          ACTIONS: {
            KEY: 'actions',
            TITLE: 'Ações',
          },
        },
      },

      NEWS_ADD: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'NEWS_ADD',
          DETAILS: 'DETALHES',
        },
        TITLE: 'Notícias',
        ERRORS: {
          TITLE: 'O campo de "título" é obrigatório',
          DESCRIPTION: 'O campo de "descrição" é obrigatório',
          FILETHUMBNAIL: 'Adicione a imagem para a listagem da notícia'
        },
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Notícias',
          PAGE_TITLE: 'Mensagem notícias',
          PAGE_IMAGE: 'Adicionar Imagem',
          PAGE_IMAGE_DESCRIPTION:
            'A primeira imagem sempre será a capa da notícia, as demais vão aparecer no detalhe da mesma. Tamanho máximo de 1500x1500px',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
          ADD: {
            LABELS: {
              TITLE: 'Título',
              DESCRIPTION: 'Descrição',
              FILE_URL: 'Faça o upload de um arquivo',
              DATE: 'Data',
              BUTTON: 'Salvar',
              NEWS_IMAGE_LIST: 'Adicione a imagem para a listagem da noticia',
              NEWS_IMAGE_DETAIL: 'Adicione a imagem para os detalhes da noticia',
            },
          },
        },
      },

      PASSWORDS: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'PASSWORDS',
          DETAILS: 'DETALHES',
          URL_ADD: '/atividades/senhas-diarias/detalhes/:id?',
        },
        MESSAGES: {
          FILE_SUCCESS: 'Arquivo adicionado com sucesso',
          FILE_ERROR: 'Formato de arquivo não permitido',
          NO_FILE: 'Upload de áudio é obrigatório!',
          NO_TITLE: 'Titulo é obrigatório!',
          NO_DESCRIPTION: 'Descrição é obrigatório!',
          SUBMIT_ERROR: 'Arquivo faltando',
          INVALID_DATE: 'Data inválida',
          SUCCESS_ADD_MESSAGE: 'Senha adicionada com sucesso.',
          SUCCESS_REMOVE_MESSAGE: 'Senha removida com sucesso.',
          SUCCESS_EDIT_MESSAGE: 'Senha editada com sucesso.',
        },
        LABELS: {
          TITLE: 'Título',
          DESCRIPTION: 'Descrição',
          TITLE_DAILY_MEDITATION: 'Meditação Diária',
          DESCRIPTION_DAILY_MEDITATION: 'Descrição da Meditação Diária',
          DATE: 'Data',
          AUDIO_UPLOAD: 'Upload de áudio',
          IMAGE_UPLOAD: 'Imagem para compartilhar',
          MEDIA_EXTERNAL_URL: 'Link do Youtube',
          SUBMIT: 'Cadastrar',
          ACTIONS: 'Ações',
          INCLUDE_DAILY_PASSWORD: 'Inclua aqui a senha diária',
          INCLUDE_DAILY_MEDITATION: 'Inclua aqui a meditação diária',
          INCLUDE_DAILY_PASSWORD_IMAGE: 'Inclua aqui a imagem da senha diária',
          UPLOAD_DAILY_PASSWORD: 'Upload da senha diária',
          UPLOAD_DAILY_MEDITATION: 'Upload de audio meditação diária',
        },
        TITLE: 'Senhas diárias',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Senhas diárias',
          PAGE_TITLE: 'Senhas diárias',
          DESCRIPTION: 'Preencha com os campos com as mensagens indicadas:',
          ADVANCED_FILTER: {
            TITLE: 'Título',
            TITLE_FIELD: 'title',
            DESCRIPTION_FIELD: 'description',
            DATE: 'Data',
            DATE_FIELD: 'date',
            ACTIONS: 'actions',
          },
          ADD: {
            LABELS: {
              TITLE: 'Título',
              DESCRIPTION: 'Descrição',
              FILE_URL: 'Faça o upload de um arquivo',
              DATE: 'Data',
              BUTTON: 'Salvar',
            },
          },
          TABLE: {
            FIELD_TITLE: 'title',
            TITLE: 'Título',
            FIELD_DATA: 'data',
            DATA: 'Data',
            FIELD_TYPE: 'type',
            TYPE: 'Tipo de Evento',
          },
        },
      },
      ABOUT_PASSWORD: {
        TITLE: 'Sobre as senhas diárias',
        DESCRIPTION: 'Preencha o campo com o texto informativo sobre as senhas diárias'
      },
      CALL_TO_ACTION: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'PASSWORDS',
          DETAILS: 'DETALHES',
          URL_ADD: '/atividades/senhas-diarias/detalhes/:id?',
        },
        ENUMS: {
          EVENTS_DETAILS: 'Detalhes de Eventos',
          NEWS_DETAILS: 'Detalhes de Notícias',
          OFFERS_DETAILS: 'Detalhes de Ofertas',
          COMMUNITY_DETAILS: 'Onde estamos',
          DAILY_PASSWORD: 'Senhas diárias',
          EVENTS_LIST: 'Listagem de eventos',
          KNOW_IECLB: 'Conheça a IECLB',
          MY_SYNOD: 'Meu sínodo',
          OFFERS_LIST: 'Lista de ofertas',
          NEWS_LIST: 'Lista de notícias',
          COMMUNITY_LIST: 'Onde estamos (Lista)',
        },
        MESSAGES: {
          SUCCESS_ADD_MESSAGE: 'Botão de ação adicionado com sucesso',
          SUCCESS_REMOVE_MESSAGE: 'Botão de ação removido com sucesso.',
          SUCCESS_EDIT_MESSAGE: 'Botão de ação editado com sucesso.',
        },
        LABELS: {
          TITLE: 'Título',
          DESCRIPTION: 'Descrição',
          DATE: 'Data',
          UPLOAD: 'Upload de áudio',
          SUBMIT: 'Cadastrar',
          ACTIONS: 'Ações',
        },
        TITLE: 'Senhas diárias',
        REPORT: {
          TITLE: 'Botões de Ação',
          PAGE_TITLE: 'Botões de Ação',
          DESCRIPTION: 'Botões de ação cadastrados na plataforma.',
          FIELDS: {
            ID: {
              KEY: 'id',
              TITLE: 'id',
            },
            NAME: {
              KEY: 'name',
              TITLE: 'Nome',
            },
            DESCRIPTION: {
              KEY: 'description',
              TITLE: 'Descrição',
            },
            CREATED_AT: {
              KEY: 'createdAt',
              TITLE: 'Criação',
            },
            ACTIONS: {
              KEY: 'actions',
              TITLE: 'Ações',
            },
          },
        },
        DETAILS: {
          TITLE_ADD: 'Adicionar botão de ação',
          TITLE_EDIT: 'Editar botão de ação',
          PAGE_DESCRIPTION_ADD:
            'Preencha os campos abaixo para adicionar um botão de ação',
          PAGE_DESCRIPTION_EDIT:
            'Preencha os campos abaixo para editar um botão de ação',
          FORM: {
            FIELDS: {
              NAME: {
                LABEL: 'Nome',
              },
              DESCRIPTION: {
                LABEL: 'Descrição',
              },
              ACTION_TYPE: {
                LABEL: 'Tipo de ação',
              },
              SCREEN_TYPE: {
                LABEL: 'Botão no app',
              },
              TO_SCREEN_TYPE: {
                LABEL: 'Navegar para',
              },
              URL: {
                LABEL: 'Navegar para url',
              },
              DETAIL_NAVIGATION: {
                LABEL: 'Navegar para tela de detalhe?',
              },
            },
          },
        },
      },

      PASSWORDS_ADD: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'PASSWORDS_ADD',
          DETAILS: 'DETALHES',
        },
        TITLE: 'Senhas diárias',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Senhas diárias',
          PAGE_TITLE: 'Senhas diárias',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
          ADD: {
            LABELS: {
              TITLE: 'Título',
              DESCRIPTION: 'Descrição',
              FILE_URL: 'Faça o upload de um arquivo',
              DATE: 'Data',
              DATE_PLACEHOLDER: '01/01/2021 12:00',
              BUTTON: 'Salvar',
            },
          },
        },
      },

      NOTIFICATIONS: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'NOTIFICATIONS',
          DETAILS: 'DETALHES',
          URL_ADD: '/atividades/notificacoes/detalhes/:id?',
        },
        MESSAGES: {
          SUCCESS: 'Notificação agendada com sucesso',
        },
        ERRORS: {
          SEND_DATE: 'Data ou hora de envio inválidas',
        },
        TITLE: 'Notificações',
        DETAILS: {
          PAGE_TITLE: 'Notificações',
          PAGE_DESCRIPTION:
            'Preencha os campos abaixo para editar um liderança',
          PLACEHOLDER_DATE: '11/11/2021',
          PLACEHOLDER_TIME: '14:00',
        },
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Notificações',
          PAGE_TITLE: 'Notificações',
          PAGE_DESCRIPTION: 'Notificações cadastradas na plataforma',
          ADVANCED_FILTER: {
            TITLE: 'Título',
            DATE: 'Data',
          },
          ADD: {
            LABELS: {
              TITLE: 'Título',
              DESCRIPTION: 'Descrição',
              FILE_URL: 'Faça o upload de um arquivo',
              DATE: 'Data e hora de envio',
              BUTTON: 'Salvar',
              DATETIME: 'Data e hora',
              DATE_PLACEHOLDER: 'dd/mm/aaaa hh/mm',
            },
          },
          FIELDS: {
            ID: {
              KEY: 'id',
              TITLE: 'id',
            },
            TITLE: {
              KEY: 'title',
              TITLE: 'Título',
            },
            BODY: {
              KEY: 'body',
              TITLE: 'Corpo',
            },
            CREATED_AT: {
              KEY: 'scheduledDate',
              TITLE: 'Data de lançamento',
            },
            ACTIONS: {
              KEY: 'actions',
              TITLE: 'Ações',
            },
          },
        },
      },

      NOTIFICATIONS_ADD: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'NOTIFICATIONS_ADD',
          DETAILS: 'DETALHES',
        },
        TITLE: 'Notificações',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Notificações',
          PAGE_TITLE: 'Notificações',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
          ADD: {
            LABELS: {
              TITLE: 'Título',
              DESCRIPTION: 'Descrição',
              FILE_URL: 'Faça Upload de um arquivo',
              DATE: 'Data',
              BUTTON: 'Salvar',
            },
          },
        },
      },

      OCCUPATION_AREA: {
        ROUTE: {
          NAME: 'AREAS DE ATUAÇÃO',
          REPORT: 'OCCUPATION_AREA',
          DETAILS: 'DETALHES',
          URL_ADD: '/atividades/area-atuacao/detalhes/:id?',
        },
        CATEGORY: {
          REPORT: {
            TITLE: 'Categorias',
            PAGE_DESCRIPTION: 'Categorias de Áreas de atuação cadastradas na plataforma',
            ADD: {
              LABELS: {
                TITLE: 'Título',
                DESCRIPTION: 'Descrição',
                FILE_URL: 'Faça o upload de um arquivo',
                DATE: 'Data',
                BUTTON: 'Salvar',
                NAME_OCCUPATION: 'Nome da categoria',
                TYPE_OCCUPATION: 'Sobre a categoria'
              },
            },
            TABLE: {
              FIELD_TITLE: 'title',
              TITLE: 'Título',
              FIELD_DATA: 'data',
              DATA: 'Data',
              DESCRIPTION: 'Descrição',
              FIELD_DESCRIPTION: 'description',
              ACTIVE: 'Ativo',
              FIELD_ACTIVE: 'isActive',
            },
          },
          DETAIL: {
            TITLE_ADD: 'Adicionar categoria',
            TITLE_EDIT: 'Editar categoria',
            PAGE_DESCRIPTION_ADD:
              'Preencha os campos abaixo para adicionar uma categoria',
            PAGE_DESCRIPTION_EDIT:
              'Preencha os campos abaixo para editar a categoria',
          },
          ERRORS: {
            TITLE: 'Título é obrigatório.',
            NAME: 'Nome da categoria é obrigatório.',
            CATEGORY: 'Categoria é obrigatória.',
            DESCRIPTION: 'Descrição da categoria é obrigatória',
            IMAGE: 'Imagem da categoria é obrigatória',
          }
        },
        SUB_CATEGORY: {
          REPORT: {
            BUTTON_LINK: 'Adicionar',
            TITLE: 'Subcategorias',
            PAGE_TITLE: 'Subcategorias',
            PAGE_DESCRIPTION: 'Subcategorias das Áreas de atuação cadastradas na plataforma',
            ADD: {
              LABELS: {
                TITLE: 'Título',
                DESCRIPTION: 'Descrição',
                FILE_URL: 'Faça o upload de um arquivo',
                DATE: 'Data',
                BUTTON: 'Salvar',
                TYPE_OCCUPATION: 'Sobre a subcategoria',
                NAME_OCCUPATION: 'Nome da subcategoria',
              },
            },
            TABLE: {
              FIELD_TITLE: 'title',
              TITLE: 'Título',
              FIELD_DATA: 'data',
              DATA: 'Data',
              DESCRIPTION: 'Descrição',
              FIELD_DESCRIPTION: 'description',
              ACTIVE: 'Ativo',
              FIELD_ACTIVE: 'isActive',
            },
          },
          DETAIL: {
            TITLE_ADD: 'Adicionar subcategoria',
            TITLE_EDIT: 'Editar subcategoria',
            PAGE_DESCRIPTION_ADD:
              'Preencha os campos abaixo para adicionar uma subcategoria',
            PAGE_DESCRIPTION_EDIT:
              'Preencha os campos abaixo para editar a subcategoria',
          },
          FIELDS: {
            ID: {
              KEY: 'id',
              TITLE: 'ID',
            },
            TITLE: {
              KEY: 'ad.title',
              TITLE: 'Título',
            },
            CREATED_AT: {
              KEY: 'createdAt',
              TITLE: 'Data',
            },
            ACTIONS: {
              KEY: 'actions',
              TITLE: 'Ações',
            },
          },
          ERRORS: {
            TITLE: 'Título é obrigatório.',
            NAME: 'Nome da subcategoria é obrigatório.',
            CATEGORY: 'Categoria é obrigatória.',
            DESCRIPTION: 'Descrição da subcategoria é obrigatória',
            IMAGE: 'Imagem da subcategoria é obrigatória',
          }
        },
        MESSAGES: {
          SUCCESS_ADD_MESSAGE: 'Área de atuação adicionada com sucesso.',
          SUCCESS_EDIT_MESSAGE: 'Área de atuação editada com sucesso.',
          SUCCESS_DELETE_MESSAGE: 'Área de atuação deletada com sucesso.',
        },
        TITLE: 'Áreas de atuação',
      },

      OCCUPATION_AREA_DETAILS: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'OCCUPATION_AREA_DETAILS',
          DETAILS: 'DETALHES',
        },
        TITLE: 'Áreas de atuação',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Áreas de atuação',
          PAGE_TITLE: 'Áreas de atuação',
          PAGE_DESCRIPTION:
            'cadatre a sua área de atuação abaixo preenchendo as informações!',
          ADVANCED_FILTER: {
            TITLE: 'Título',
            DATE_BEGIN: 'Data de início:',
            DATE_END: 'Data final:',
            CITY: 'Cidade',
          },
          ADD: {
            LABELS: {
              UPLOAD: 'Adicione a imagem para os detalhes da área de atuação',
              UPLOAD_THUMB: 'Adicione a imagem para a listagem da área de atuação',
              PREVIEW_PDF: 'Visualizar pdf',
              TYPE_OCCUPATION: 'Sobre a área de atuação',
              NAME_OCCUPATION: 'Nome da área de atuação',
              TITLE: 'Título',
              DESCRIPTION: 'Descrição',
              MEDIA_URL: 'Link da imagem',
              BUTTON: 'Salvar',
              BUTTON_UPLOAD_MULTIPLE: 'Adicionar arquivos',
              IS_SUBCATEGORY: 'Adicionar como subcategoria?',
              SELECT_CATEGORY: 'Selecione a categoria'
            },
          },
        },
      },

      CAMPAIGNS: {
        ROUTE: {
          NAME: 'CAMPANHAS',
          REPORT: 'CAMPAIGNS',
          DETAILS: 'DETALHES',
          URL_ADD: '/atividades/campanhas/detalhes/:id?',
        },
        MESSAGES: {
          SUCCESS_ADD_MESSAGE: 'Campanhas adicionada com sucesso.',
          SUCCESS_EDIT_MESSAGE: 'Campanhas editada com sucesso.',
          SUCCESS_DELETE_MESSAGE: 'Campanhas deletada com sucesso.',
        },
        TITLE: 'Campanhas',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Campanhas',
          PAGE_TITLE: 'Campanhas',
          PAGE_DESCRIPTION: 'Campanhas cadastradas na plataforma',
          ADD: {
            LABELS: {
              TITLE: 'Título',
              DESCRIPTION: 'Descrição',
              FILE_URL: 'Faça o upload de um arquivo',
              DATE: 'Data',
              BUTTON: 'Salvar',
            },
          },
          TABLE: {
            FIELD_TITLE: 'title',
            TITLE: 'Título',
            FIELD_DATA: 'data',
            DATA: 'Data',
            FIELD_TYPE: 'scope',
            TYPE: 'Tipo de Campanha',
            DESCRIPTION: 'Descrição',
            FIELD_DESCRIPTION: 'description',
            ACTIVE: 'Oferta Ativa',
            FIELD_ACTIVE: 'isActive',
          },
        },
        DETAILS: {
          TITLE_ADD: 'Adicionar Campanha',
          TITLE_EDIT: 'Editar Campanha',
          PAGE_DESCRIPTION_ADD:
            'Preencha os campos abaixo para adicionar uma campanha',
          PAGE_DESCRIPTION_EDIT:
            'Preencha os campos abaixo para editar a campanha',
        },
        FIELDS: {
          ID: {
            KEY: 'id',
            TITLE: 'ID',
          },
          TITLE: {
            KEY: 'ad.title',
            TITLE: 'Título',
          },
          CREATED_AT: {
            KEY: 'createdAt',
            TITLE: 'Data',
          },
          ACTIONS: {
            KEY: 'actions',
            TITLE: 'Ações',
          },
        },
      },

      CAMPAIGNS_DETAILS: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'CAMPAIGNS_DETAILS',
          DETAILS: 'DETALHES',
        },
        TITLE: 'Campanhas',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Campanhas',
          PAGE_TITLE: 'Campanhas',
          PAGE_DESCRIPTION:
            'cadatre a sua campanha abaixo preenchendo as informações!',
          ADVANCED_FILTER: {
            TITLE: 'Título',
            DATE_BEGIN: 'Data de início:',
            DATE_END: 'Data final:',
            CITY: 'Cidade',
          },
          ADD: {
            LABELS: {
              UPLOAD: 'Adicione a imagem para os detalhes da campanha',
              UPLOAD_THUMB: 'Adicione a imagem da lista para a campanha',
              DATE_BEGIN: 'Data de início:',
              DATE_END: 'Data final:',
              TITLE_TIME: 'Horário da Campanha:',
              TYPE_CAMPAIGN: 'Sobre o campanha',
              CAMPAIGN: 'Tipo de campanha',
              ADD_SYNOD: 'Deseja adicionar para um sínodo?',
              IS_MAIN: 'Campanha principal?',
              IS_ACTIVE: 'Ativar botão de oferta?',
              SYNOD: 'Sínodo',
              NAME_CAMPAIGN: 'Nome da campanha',
              TITLE_DATE: 'Data da campanha:',
              TIME_BEGIN: 'Hora de início:',
              TIME_END: 'Hora Final:',
              TITLE: 'Título',
              DESCRIPTION: 'Descrição',
              ADDRESS: 'Localização',
              STREET: 'Rua',
              NUMBER: 'Número',
              NEIGHBORHOOD: 'Bairro',
              ZIPCODE: 'CEP',
              CITY: 'Cidade',
              STATE: 'Estado',
              COMPLEMENT: 'Complemento',
              LAT: 'Latitude',
              LONG: 'Longitude',
              MISCINFO: 'Outras informações',
              ADD_SUPORT_INFO: 'Deseja adicionar informações de contato?',
              MEDIA_URL: 'Link do Youtube',
              EMAIL: 'E-mail',
              PHONE: 'Telefone',
              BUTTON: 'Salvar',
              BUTTON_UPLOAD: 'Adicionar imagem',
              BUTTON_UPLOAD_MULTIPLE: 'Adicionar imagens',
              BUTTON_UPLOAD_PORTFOLIO: 'Adicionar carteirinha',
              BUTTON_REMOVE_PORTFOLIO: 'Remover carteirinha',
              BUTTON_DOWNLOAD_PORTFOLIO: 'Baixar foto',
              PDF_NAME: 'Titulo do pdf',
              PDF_ORDER_INFO: 'Clique e arraste para ordenar os pdfs',
              LABEL_PORTFOLIO: 'Carteirinha Ministerial',
            },
            ERRORS: {
              MEDIA_URL: 'Insira um link vídeo do youtube válido',
              INVALID_DATE: 'Data inválida',
              INVALID_HOUR: 'Horário inválido',
            },
          },
        },
      },

      NEW_OFFERS: {
        TITLE: 'OFERTAS',
        SIDEBAR_TITLE: 'OFERTAS',
      },
      OFFERS: {
        ROUTE: {
          NAME: 'OFERTAS',
          REPORT: 'OFFERS',
          DETAILS: 'DETALHES',
          URL_ADD: '/ofertas/detalhes/:id?',
        },
        TITLE: 'Ofertas',
        DETAILS: {
          ERRORS: {
            EVENT: 'Nome da oferta inválido',
            DESCRIPTION: 'Descrição da oferta inválido',
            INITIAL_DATE: 'Data ínicial inválida',
            FINAL_DATE: 'Data final inválida',
          },
        },
        REPORT: {
          ACTIVE_TYPE: 'Ativa',
          INACTIVE_TYPE: 'Inativa',
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Ofertas',
          DESCRIPTION: 'Ofertas cadastradas na plataforma',
          PAGE_TITLE: 'Ofertas',
          PAGE_DESCRIPTION: 'Preencha os campos com as mensagens indicadas:',
          ADVANCED_FILTER: {
            TITLE: 'Título',
            DATE_BEGIN: 'Data de início',
            DATE_END: 'Data final',
            CITY: 'Cidade',
          },
          TABLE: {
            FIELD_TITLE: 'title',
            TITLE: 'Título',
            FIELD_DESCRIPTION: 'description',
            DESCRIPTION: 'Descrição',
            FIELD_DATA_BEGIN: 'startsAtDate',
            DATA_BEGIN: 'Data inicial',
            FIELD_DATA_END: 'endsAtDate',
            DATA_END: 'Data final',
            FIELD_TYPE: 'scope',
            TYPE: 'Tipo de Oferta',
            FIELD_ACTIVE: 'isActive',
            ACTIVE: 'Oferta Ativa',
            FIELD_MAIN: 'isMain',
            MAIN: 'Principal',
            FIELD_CITY: 'address',
            CITY: 'Cidade',
          },
        },
      },

      OFFERS_DETAILS: {
        ROUTE: {
          NAME: 'OFERTAS',
          REPORT: 'OFFERS_DETAILS',
          DETAILS: 'DETALHES',
        },
        TITLE_ADD: 'Adicionar oferta',
        TITLE_EDIT: 'Editar oferta',
        PAGE_DESCRIPTION_ADD:
          'Cadastre uma nova oferta preenchendo as informações abaixo!',
        PAGE_DESCRIPTION_EDIT: 'Editar uma oferta',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Ofertas',
          TITLE_ADD: 'Ofertas',
          ADVANCED_FILTER: {
            TITLE: 'Título',
            DATE_BEGIN: 'Data de início:',
            DATE_END: 'Data final:',
            CITY: 'Cidade',
          },
          ADD: {
            LABELS: {
              UPLOAD: 'Adicione a imagem para os detalhes da oferta',
              UPLOAD_THUMB: 'Adicione a imagem para a lista de ofertas',
              TITLE_OFFERS: 'Título',
              DATE_END: 'Data final:',
              TITLE_TIME: 'Horário do Evento:',
              TYPE_EVENT: 'Des',
              EVENT: 'Tipo da oferta',
              ADD_SYNOD: 'Deseja adicionar para um sínodo?',
              SYNOD: 'Sínodo',
              NAME_EVENT: 'Nome do evento',
              TITLE_DATE: 'Data do evento:',
              TIME_BEGIN: 'Hora de início:',
              TIME_END: 'Hora Final:',
              TITLE: 'Título da oferta',
              TITLE_DESCRIPTION: 'Descrição da oferta',
              DESCRIPTION: 'Descrição',
              ADDRESS: 'Localização',
              STREET: 'Rua',
              NUMBER: 'Número',
              NEIGHBORHOOD: 'Bairro',
              ZIPCODE: 'CEP',
              CITY: 'Cidade',
              STATE: 'Estado',
              COMPLEMENT: 'Complemento',
              LAT: 'Latitude',
              LONG: 'Longitude',
              MISCINFO: 'Outras informações',
              ADD_SUPORT_INFO: 'Deseja adicionar informações de contato?',
              MEDIA_URL: 'Link do Youtube',
              EMAIL: 'E-mail',
              PHONE: 'Telefone',
              BUTTON: 'Salvar',
              BUTTON_UPLOAD: 'Adicionar imagem',
              BUTTON_UPLOAD_MULTIPLE: 'Adicionar imagens',
            },
            ERRORS: {
              MEDIA_URL: 'Insira um vídeo do youtube válido',
              INVALID_DATE: 'Data inválida',
              INVALID_HOUR: 'Horário inválido',
            },
          },
        },
      },

      OFFERS_RECEIVED: {
        ROUTE: {
          NAME: 'OFERTAS',
          REPORT: 'OFFERS_RECEIVED',
          DETAILS: 'DETALHES',
          URL_ADD: '/ofertas/detalhes/:id?',
        },
        TITLE: 'Ofertas Recebidas',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Ofertas recebidas',
          DESCRIPTION: 'Ofertas recebidas na plataforma',
          PAGE_TITLE: 'Ofertas',
          PAGE_DESCRIPTION: 'Preencha os campos com as mensagens indicadas:',
          ENUMS: {
            CREDIT_CARD: 'Cartão de crédito',
            BANK_SLIP: 'Boleto bancário',
            PIX: 'PIX',
          },
          ADVANCED_FILTER: {
            TITLE: 'Título',
            DATE_BEGIN: 'Data de início',
            DATE_END: 'Data final',
            CITY: 'Cidade',
          },
          TABLE: {
            FIELD_ID: 'id',
            ID: 'id',
            FIELD_VALUE: 'value',
            VALUE: 'Valor',
            FIELD_PAYMENT_TYPE: 'paymentType',
            PAYMENT_TYPE: 'Tipo de pagamento',
            FIELD_EVENT: 'event',
            EVENT: 'Evento',
            FIELD_CREATED_AT: 'createdAt',
            CREATED_AT: 'Data da oferta',
          },
        },
      },

      OFFERS_RECEIVED_DETAILS: {
        ROUTE: {
          NAME: 'OFERTAS',
          REPORT: 'OFFERS_RECEIVED_DETAILS',
          DETAILS: 'DETALHES',
        },
        TITLE: 'Ofertas',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Ofertas recebidas',
          PAGE_TITLE: 'Ofertas',
          PAGE_DESCRIPTION: 'Informações sobre oferta recebida',
          FIELDS: {
            EVENT_NAME: 'Oferta',
            VALUE: 'Valor',
            CREATED_AT: 'Ofertado em',
            PAYMENT_TYPE: 'Forma de pagamento',
          },
        },
      },

      MY_SYNOD: {
        ROUTE: {
          NAME: 'MEU_SINODO',
          INITIAL: 'INITIAL',
          LEADERS: 'LEADERS',
          OCCUPATION_AREA: 'OCCUPATION_AREA',
        },
        INITIAL: {
          PAGE_TITLE: 'Mensagem início',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
        },
        LEADERS: {
          PAGE_TITLE: 'Mensagem lideranças',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
        },
        OCCUPATION_AREA: {
          PAGE_TITLE: 'Mensagem áreas de atuação',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
        },
        FORM: {
          TITLE: 'Insira o título da mensagem',
          DESCRIPTION: 'Insira a mensagem',
          OTHER_INFO: 'Insira as informações adicionais',
          URL: 'Insira a URL do vídeo a ser postado no aplicativo',
        },
        SUCCESS_EDIT_MESSAGE: 'Sínodo editado com sucesso!',
      },

      SYNOD: {
        ROUTE: {
          NAME: 'USUARIOS',
          REPORT: 'SYNOD',
          DETAILS: 'DETALHES',
          ADMIN_REPORT: 'ADMIN_REPORT',
        },
        TITLE: 'SÍNODOS',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          ADD: {
            LABELS: {
              NAME: 'Nome do sínodo',
              PARISH: 'Nome da paróquia',
              COMMUNITY: 'Nome da comunidade',
            },
          },
          TITLE: 'Relatório',
          PAGE_TITLE: 'Relatório de sínodos',
          PAGE_DESCRIPTION: 'Listagem de sínodos cadastrados na plataforma',
          PAGE_TITLE_DETAILS: 'Detalhes',

          SEARCH_BAR: {
            SEARCH: {
              LABEL: 'O que deseja procurar?',
            },
            STATUS: {
              LABEL: 'STATUS',
            },
            FILTERS: {
              LABEL: 'FILTROS',
            },
          },

          ADVANCED_FILTER: {
            USERNAME: 'Nome',
            INIT_TITLE: 'Nome Inicial',
            LEADER_SHIP_TITLE: 'liderança do Sínodo',
            CREATED_AT: 'Criado em',
          },
        },

        DETAILS: {
          SUCCESS_ADD_MESSAGE: 'Sínodo adicionado com sucesso!',
          UNDEFINED: 'Não definido',
          TITLE: 'Sínodo',
          PAGE_TITLE: 'Sínodo',
          PAGE_TITLE_BREAD: 'Detalhe sínodo',
          PAGE_DESCRIPTION: 'Data de cadastro: 05/10/2020',

          SEARCH_BAR: {
            SEARCH: {
              LABEL: 'O que deseja procurar?',
            },
            STATUS: {
              LABEL: 'STATUS',
            },
            FILTERS: {
              LABEL: 'FILTROS',
            },
          },
          ADVANCED_FILTER: {
            USERNAME: 'Usuário',
            CELLPHONE: 'Celular',
          },
          TABS: {
            ONE: 'EDITAR DETALHES',
            TWO: 'PAGAMENTOS',
            THREE: 'DEPÓSITOS',
            FOUR: 'TRANSFERÊNCIAS',
            FIVE: 'SOLICITAÇÕES',
            SIX: 'CARTÕES',
          },
          FORM: {
            ERROR: {
              EMPTY_FIELD: 'Preencha todos os campos!',
            },
            BUTTON: {
              LABEL: 'Salvar',
            },
            BACK: {
              LABEL: 'Voltar',
            },
            TITLE: 'Detalhes do sínodo',
            NAME: {
              LABEL: 'Nome sínodo',
            },
            VIDEO_URL: {
              LABEL: 'Insira a url do vídeo no Youtube',
            },
            INIT_TITLE: {
              LABEL: 'Título principal',
            },
            INIT_DESCRIPTION: {
              LABEL: 'Descrição',
            },
            LEADER_SHIP_TITLE: {
              LABEL: 'Nome liderança sinodal',
            },
            LEADER_SHIP_DESCRIPTION: {
              LABEL: 'Descrição liderança sinodal',
            },
            OCCUP_AREA_TITLE: {
              LABEL: 'Título área',
            },
            OCCUP_AREA_DESCRIPTION: {
              LABEL: 'Descrição área',
            },
            MORE_INFO: {
              LABEL: 'Informações (número de telefone, e-mail, etc)',
            },
          },
        },

        ONBOARDING: {
          TITLE: 'Onboarding',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPANY: {
          TITLE: 'Empresa',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPLIANCE: {
          TITLE: 'Compliance',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
      },

      PARISH: {
        PAGE_TITLE_BREAD: 'Detalhe',
        ROUTE: {
          NAME: 'USUARIOS',
          REPORT: 'PARISH',
          DETAILS: 'DETALHES',
          URL_ADD: '/usuarios/paroquia/detalhes/:id?',
        },
        TITLE: 'PARÓQUIAS',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          ADD: {
            LABELS: {
              PARISH: 'Nome da paróquia',
              SYNOD: 'Nome do sínodo',
              COMUNITY: 'Nome da comunidade',
            },
          },
          TITLE: 'Relatório',
          PAGE_TITLE: 'Relatório de paróquias',
          PAGE_DESCRIPTION: 'Paróquias cadastradas na plataforma',

          ADVANCED_FILTER: {
            USERNAME: 'Nome da Paróquia',
            CITY: 'Cidade',
            STREET: 'Rua',
          },
        },

        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE: 'Sínodo',
          PAGE_TITLE: 'Sínodo',
          PAGE_DESCRIPTION: 'Data de cadastro: 05/10/2020',

          SEARCH_BAR: {
            SEARCH: {
              LABEL: 'O que deseja procurar?',
            },
            STATUS: {
              LABEL: 'STATUS',
            },
            FILTERS: {
              LABEL: 'FILTROS',
            },
          },
          ADVANCED_FILTER: {
            USERNAME: 'Usuário',
            CELLPHONE: 'Celular',
          },
          TABS: {
            ONE: 'EDITAR DETALHES',
            TWO: 'PAGAMENTOS',
            THREE: 'DEPÓSITOS',
            FOUR: 'TRANSFERÊNCIAS',
            FIVE: 'SOLICITAÇÕES',
            SIX: 'CARTÕES',
          },
          FORM: {
            TITLE: 'Editar detalhes do usuário',
            NAME: {
              LABEL: 'Nome da paróquia',
            },
            SYNOD_ID: {
              LABEL: 'Sínodo pertencente',
            },
            ADDRESS: {
              LABEL: 'Localização',
            },
            STREET: {
              LABEL: 'Rua',
            },
            NUMBER: {
              LABEL: 'Número',
            },
            ZIPCODE: {
              LABEL: 'CEP',
            },
            NEIGHBORHOOD: {
              LABEL: 'Bairro',
            },
            CITY: {
              LABEL: 'Cidade',
            },
            STATE: {
              LABEL: 'Estado',
            },
            COMPLEMENT: {
              LABEL: 'Complemento',
            },
            LATITUDE: {
              LABEL: 'Latitude',
            },
            LONGITUDE: {
              LABEL: 'Longitude',
            },
            BACK: {
              LABEL: 'Voltar',
            },
            BUTTON: {
              LABEL: 'Salvar',
            },
            ERROR: 'Campos faltando',
          },
        },
      },

      LEADERS: {
        PAGE_TITLE_BREAD: 'Lideranças',
        ROUTE: {
          NAME: 'USUARIOS',
          REPORT: 'LEADERS',
          DETAILS: 'DETAILS',
          URL_ADD: '/usuarios/lideres/detalhes/:id?',
        },
        TITLE: 'Lideranças',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          ADD: {
            LABELS: {
              PARISH: 'Nome da paróquia',
              SYNOD: 'Nome do sínodo',
              COMUNITY: 'Nome do(a) liderança',
            },
          },
          TITLE: 'Relatório',
          PAGE_TITLE: 'Relatório de Lideranças',
          PAGE_DESCRIPTION: 'Vizualize e gerencie os lideranças',

          SEARCH_BAR: {
            SEARCH: {
              LABEL: 'O que deseja procurar?',
            },
            STATUS: {
              LABEL: 'STATUS',
            },
            FILTERS: {
              LABEL: 'FILTROS',
            },
          },

          ADVANCED_FILTER: {
            USERNAME: 'Nome da liderança',
            CITY: 'Nome da cidade',
            STREET: 'Nome da rua',
          },
        },

        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE: 'Lideranças',
          PAGE_TITLE: 'Lideranças',
          PAGE_DESCRIPTION: 'Adicione um liderança',

          SEARCH_BAR: {
            SEARCH: {
              LABEL: 'O que deseja procurar?',
            },
            STATUS: {
              LABEL: 'STATUS',
            },
            FILTERS: {
              LABEL: 'FILTROS',
            },
          },
          ADVANCED_FILTER: {
            USERNAME: 'Usuário',
            CELLPHONE: 'Celular',
          },
          TABS: {
            ONE: 'EDITAR DETALHES',
            TWO: 'PAGAMENTOS',
            THREE: 'DEPÓSITOS',
            FOUR: 'TRANSFERÊNCIAS',
            FIVE: 'SOLICITAÇÕES',
            SIX: 'CARTÕES',
          },
          FORM: {
            TITLE: 'Editar detalhes do(a) liderança',
            NAME: {
              LABEL: 'Nome do(a) liderança',
            },
            SYNOD: {
              ID: {
                LABEL: 'ID sínodo',
              },
              LABEL: 'Sinodo pertencente',
            },
            PARISH: {
              ID: {
                LABEL: 'ID paróquia',
              },
              LABEL: 'Paróquia pertencente',
            },

            ROLE_TYPE: {
              COUNSELOR: 'Conselheiro(a)',
              BOARD_MEMBER: 'Diretor(a)',
              MINISTER: 'Ministro(a)',
            },

            ROLE_DESCRIPTION: {
              LABEL: 'Descrição de liderança',
            },

            INSTITUTION: {
              LABEL: 'Instituição pertencente',
            },

            BUTTON: {
              LABEL: 'Salvar',
            },
            MESSAGES: {
              SUCCESS: 'liderança adicionado com sucesso',
            },
          },
        },

        ONBOARDING: {
          TITLE: 'Onboarding',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPANY: {
          TITLE: 'Empresa',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPLIANCE: {
          TITLE: 'Compliance',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
      },

      COMMUNITY: {
        PAGE_TITLE_BREAD: 'Detalhes',
        ROUTE: {
          NAME: 'USUARIOS',
          REPORT: 'COMMUNITY',
          DETAILS: 'DETALHES',
          URL_ADD: '/usuarios/comunidade/detalhes/:id?',
        },
        TITLE: 'COMUNIDADES',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          ADD: {
            LABELS: {
              PARISH: 'Nome da paróquia',
              SYNOD: 'Nome do sínodo',
              COMUNITY: 'Nome da comunidade',
            },
          },
          TITLE: 'Relatório',
          PAGE_TITLE: 'Relatório de Comunidades',
          PAGE_DESCRIPTION: 'Comunidades cadastradas na plataforma',

          SEARCH_BAR: {
            SEARCH: {
              LABEL: 'O que deseja procurar?',
            },
            STATUS: {
              LABEL: 'STATUS',
            },
            FILTERS: {
              LABEL: 'FILTROS',
            },
          },

          ADVANCED_FILTER: {
            USERNAME: 'Nome da Comunidade',
            CITY: 'Nome da cidade',
            STREET: 'Nome da rua',
          },
        },

        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE: 'Sínodo',
          PAGE_TITLE: 'Sínodo',
          PAGE_TITLE_BREAD: 'Detalhe sínodo',
          PAGE_DESCRIPTION: 'Data de cadastro: 05/10/2020',

          SEARCH_BAR: {
            SEARCH: {
              LABEL: 'O que deseja procurar?',
            },
            STATUS: {
              LABEL: 'STATUS',
            },
            FILTERS: {
              LABEL: 'FILTROS',
            },
          },
          ADVANCED_FILTER: {
            USERNAME: 'Usuário',
            CELLPHONE: 'Celular',
          },
          TABS: {
            ONE: 'EDITAR DETALHES',
            TWO: 'PAGAMENTOS',
            THREE: 'DEPÓSITOS',
            FOUR: 'TRANSFERÊNCIAS',
            FIVE: 'SOLICITAÇÕES',
            SIX: 'CARTÕES',
          },
          FORM: {
            TITLE: 'Editar detalhes do usuário',
            NAME: {
              LABEL: 'Nome da comunidade',
            },
            PARISH_ID: {
              LABEL: 'Sínodo pertencente',
            },
            ADD_CAM: {
              LABEL: 'Adicionar a um CAM?',
            },
            ADDRESS: {
              LABEL: 'Localização',
            },
            STREET: {
              LABEL: 'Rua',
            },
            NUMBER: {
              LABEL: 'Número',
            },
            ZIPCODE: {
              LABEL: 'CEP',
            },
            NEIGHBORHOOD: {
              LABEL: 'Bairro',
            },
            CITY: {
              LABEL: 'Cidade',
            },
            STATE: {
              LABEL: 'Estado',
            },
            COMPLEMENT: {
              LABEL: 'Complemento',
            },
            LATITUDE: {
              LABEL: 'Latitude',
            },
            LONGITUDE: {
              LABEL: 'Longitude',
            },
            BACK: {
              LABEL: 'Voltar',
            },
            BUTTON: {
              LABEL: 'Salvar',
            },
          },
        },

        ONBOARDING: {
          TITLE: 'Onboarding',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPANY: {
          TITLE: 'Empresa',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPLIANCE: {
          TITLE: 'Compliance',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
      },

      PUSH_NOTIFICATIONS: {
        TITLE: 'PUSH NOTIFICATIONS',
        REPORT: {
          TITLE: 'Push notifications',
          PAGE_TITLE: 'Push notifications',
          PAGE_DESCRIPTION: 'Notificações cadastradas na plataforma',

          SEARCH_BAR: {
            SEARCH: {
              LABEL: 'O que deseja procurar?',
            },
            STATUS: {
              LABEL: 'STATUS',
            },
            FILTERS: {
              LABEL: 'FILTROS',
            },
          },

          ADVANCED_FILTER: {
            USERNAME: 'Usuário',
            CELLPHONE: 'Celular',
          },
        },

        DETAILS: {
          TITLE_ADD: 'Adicionar',
          TITLE_EDIT: 'Editar push notification',
          DESCRIPTION_ADD:
            'Preencha o formulário abaixo para criar uma notificação',
          STEPS: {
            ONE: 'Filtre os usuários',
            TWO: 'Defina os dados da notificação',
            THREE: 'Ajuste a recorrência e agendamento',
          },
          FORM: {
            TITLE: {
              LABEL: 'Título',
            },
            DESCRIPTION: {
              LABEL: 'Descrição',
            },
            SCHEDULE: {
              LABEL: 'Agendamento',
              NOW: 'Enviar agora',
              SCHEDULED: 'Selecionar data',
            },
            SEND_TYPE: {
              LABEL: 'Forma de envio',
              ALL: 'Todos os usuários',
              CSV: 'Selecionar usuários',
              CSV_BUTTON_TEXT: 'Selecione o arquivo CSV',
              CSV_BUTTON_TEXT_CHANGE: 'Selecionar outro arquivo',
              CSV_TEMPLATE_TEXT: 'Download do template',
            },
            RECURRENCE: {
              LABEL: 'Recorrência',
              JUST_SEND: 'Apenas uma vez',
              DAILY: 'Diariamente',
              DAILY_TIME: 'Hora do dia',
              WEEKLY: 'Semanalmente',
              MONTLY: 'Mensalmente',
              MONTLY_DAY: 'Dia do mês',
              EXPIRATION_DATE: 'Data de expiração',
              UNDEFINED_EXPIRATION: 'Deixe em branco para nunca expirar.',
              DAYS_OF_WEEK: {
                TITLE: 'Dia da semana',
                ONE: 'Domingo',
                TWO: 'Segunda-feira',
                THREE: 'Terça-feira',
                FOUR: 'Quarta-feira',
                FIVE: 'Quinta-feira',
                SIX: 'Sexta-feira',
                SEVEN: 'Sábado',
              },
            },
            USERS_AMOUNT: {
              LABEL: 'Será enviado para',
              LABEL_TWO: 'usuário(s).',
            },
          },
        },

        ONBOARDING: {
          TITLE: 'Onboarding',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPANY: {
          TITLE: 'Empresa',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPLIANCE: {
          TITLE: 'Compliance',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
      },

      REPORTS: {
        TITLE: 'RELATÓRIOS',
        REPORT: {
          TITLE: 'Movimentação',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        ONBOARDING: {
          TITLE: 'Tarifas',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPANY: {
          TITLE: 'Resumo transações',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPLIANCE: {
          TITLE: 'Consolidação diária',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
      },

      MOVIMENTATION: {
        TITLE: 'MOVIMENTAÇÃO',
        SIDEBAR_ICON_URL: '',

        PAYMENTS: {
          TITLE: 'Pagamentos',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        RECEIPTS: {
          TITLE: 'Recebimentos',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
      },

      SETTINGS: {
        TITLE: 'CONFIGURAÇÕES',
        SECURITY: {
          TITLE: 'Segurança',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        ACCOUNTS: {
          TITLE: 'Contas',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        GENERAL: {
          TITLE: 'Geral',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
      },
      NOT_FOUND: {
        TITLE: 'Erro 404',
        PAGE_DESCRIPTION:
          'Parece que não encontramos a página solicitada.\nVerifique a url digitada e tente novamente.',
      },
    },
  },

  ENUMERATORS: {
    ACTION_TYPE: {
      INTERNAL_NAVIGATION: {
        VALUE: 1,
        LABEL: 'Navegação interna',
      },
      EXTERNAL_NAVIGATION: {
        VALUE: 2,
        LABEL: 'Navegação externa',
      },
    },

    PROFILE_TYPE: {
      ADMIN: {
        VALUE: '1',
        LABEL: 'Nacional',
      },
      SYNOD_ADMIN: {
        VALUE: '2',
        LABEL: 'Sinodal',
      },
      APP_USER: {
        VALUE: '3',
        LABEL: 'Usuário(a)',
      },
      MINISTER_USER: {
        VALUE: '4',
        LABEL: 'Ministro(a)',
      },
    },

    EVENT_TYPE: {
      EVENT: 'Evento',
      REMIDER: 'Lembrete',
      OFFERING: 'Oferta',
      MESSAGE: 'Mensagem',
    },

    SCOPE_TYPE: {
      SYNODAL: 'Sínodal',
      NATIONAL: 'Nacional',
      PAROCHIAL: 'Local',
    },

    LEADER_ROLE: {
      MINISTER: {
        VALUE: '1',
        LABEL: 'Ministras e ministros',
      },
      COUNCIL: {
        VALUE: '2',
        LABEL: 'Diretoria sinodal',
      },
      BOARD: {
        VALUE: '3',
        LABEL: 'Conselhos e coordenações',
      },
      PRESIDENT: {
        VALUE: '4',
        LABEL: 'Presidente',
      },
      EMERITUS: {
        VALUE: '5',
        LABEL: 'Emerito',
      },
    },
  },

  SHARED: {
    FORMAT_DATE: 'dd/MM/yyyy',
    FORMAT_HOUR: 'HH:mm',
    FORMAT_DATE_TIME: 'dd/MM/yyyy HH:mm',
    CURRENCY_SYMBOL: 'R$',
    SELECT_SOMETHING: 'Selecione...',
    ADD_NEW: 'Adicionar',
    IMPORT_NEW: 'Importar CSV',
    BACK: 'Voltar',
    SAVE: 'Salvar',
    SEND_PASSWORD: 'Enviar senha',
    ADD_ID: 'adicionar',
    CANCEL: 'Cancelar',
    DAY: 'Dia',
    YES: 'Sim',
    NO: "Não",
    CONFIRM_UNMINISTER_USER: 'Tem certeza que desejar remover o título de ministro(a)?',
    TURN_USER_MINISTER: 'Tornar usuário(a) ministro(a)',
    PREVIEW_IMAGE_MINISTER: 'Imagem preview capturada pelo aplicativo',
    PREVIEW_IMAGE: 'Preview',
    PREVIEW_IMAGE_UPLOADER: 'Nenhuma preview adicionada.',
  },
};

export default ptBr;
