import { i18nReducer } from 'react-redux-i18n';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { thunk } from 'redux-thunk';

import authReducer from './auth';
import adminReducer from './admin';
import communityReducer from './community';
import InstitutionalReducer from './institutional';
import leadersReducer from './leaders';
import loadingReducer from './loading';
import notificationReducer from './notification';
import parishReducer from './parish';
import synodReducer from './synod';
import userReducer from './user';
import announcementReducer from './announcement';
import utilsReducer from './utils';
import scheduleReducer from './schedule';
import newsReducer from './news';
import offersReducer from './offers';
import pdfReducer from './pdf';
import stateReducer from './state';
import callToActionReducer from './callToAction';
import transactionReducer from './transaction';
import campaignsReducer from './campaign';
import messagesReducer from './messages';
import worshipReducer from './worship';
import occupationAreaReducer from './occupationArea';
import worshipContentReducer from './worshipContent';

const appReducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  i18n: i18nReducer,
  loading: loadingReducer,
  user: userReducer,
  synod: synodReducer,
  parish: parishReducer,
  leader: leadersReducer,
  community: communityReducer,
  notification: notificationReducer,
  utils: utilsReducer,
  schedule: scheduleReducer,
  institutional: InstitutionalReducer,
  news: newsReducer,
  announcement: announcementReducer,
  offers: offersReducer,
  pdf: pdfReducer,
  state: stateReducer,
  callToAction: callToActionReducer,
  transaction: transactionReducer,
  campaigns: campaignsReducer,
  messages: messagesReducer,
  worship: worshipReducer,
  occupationArea: occupationAreaReducer,
  worshipContent: worshipContentReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'AUTH_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

const storeCreator = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f: any) => f
  )
);

export default storeCreator;
