import getInstance from './instance';

const BASE_URL: string = '/user';

const UserApi = {
  adminMe: async () => {
    const instance = await getInstance();
    const { data } = await instance.get(`${BASE_URL}/admin/me`);

    return data;
  },

  me: async () => {
    const instance = await getInstance();
    const { data } = await instance.get(`${BASE_URL}/me`);

    return data;
  },

  getReport: async (params: advancedFilterModels.IUserAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get(BASE_URL, { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`${BASE_URL}/${id}`);

    return data;
  },

  create: async (params: models.User) => {
    const instance = await getInstance();
    const { data } = await instance.post(BASE_URL, params);

    return data;
  },

  registerPhoto: async (id?: string, params?: models.DigitalDocument ) => {
    const instance = await getInstance();
    const { data } = await instance.put(`${BASE_URL}/photo/${id}`, { digitalDocument: params });

    return data;
  },

  downloadPhoto: async (id?: string) => {
    const instance = await getInstance();
    const response = await instance.get(`${BASE_URL}/photo/${id}/photo`, {
      responseType: 'blob',
    });

    return response;
  },

  remove: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.delete(`${BASE_URL}/${id}`);

    return data;
  },

  update: async (id?: string, params?: any) => {
    const instance = await getInstance();
    const { data } = await instance.put(`${BASE_URL}/${id}`, params);

    return data;
  },

  turnUserMinister: async (csvFile: FormData) => {
    const instance = await getInstance();
    const { data } = await instance.post(`${BASE_URL}/update-to-minister`, csvFile, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return data;
  },
};

export default UserApi;
